import { Box, MenuItem, Typography, styled } from "@mui/material";

export const GreenText = styled(Typography)(({ theme }) => ({
  color: theme.palette.info.main,
  marginBottom: theme.spacing(2),
  fontSize: "18px",
  whiteSpace: "pre-line",
}));

export const HeaderBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  marginBottom: theme.spacing(2),
}));

export const InfoSection = styled(Box)(({ theme }) => ({
  backgroundColor: "#F8F9FA",
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  marginTop: theme.spacing(2),
}));

export const StyleMenuItem = styled(MenuItem)`
  display: flex;
  align-items: center;
`;

export const ImageFlag = styled("img")`
  width: 25px;
  margin-right: 3px;
`;

export const TypoTitle = styled(Typography)`
  font-size: 18px;
  margin-bottom: 8px;
`;

export const TypoTitle2 = styled(Typography)`
  font-size: 19px;
  font-weight: bold;
  margin-bottom: 4px;
`;

export const TypoDescription = styled(Typography)`
  font-size: 15px;
  text-align: center;
  color: #666;
  font-weight: 600;
`;

export const TypoAttachment = styled(Typography)`
  font-size: 15px;
  color: #004346;
  font-weight: 600;
  margin-top: 8px;
  margin-left: 8px;
`;

export const StyledStrong = styled("strong")`
  margin-left: 8px;
`;

export const WrapperImageQr = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ImageQR = styled("img")`
  width: 200px;
  margin-right: 3px;
`;
