import { FILE_CONFIG, MIME_TYPES } from "constants/fileConstants";
import { jsPDF } from "jspdf";
import autoTable, { Styles } from "jspdf-autotable";
import { FileExtension, FileTable, MimeType } from "types/common";

export const fileTableToPDF = (
  fileTable: FileTable,
  tableName: string,
  columnStyles?: Record<string | number, Partial<Styles>>,
): Uint8Array => {
  const { header, rows } = fileTable;

  const doc = new jsPDF(FILE_CONFIG.PDF_FILE);

  doc.text(
    tableName,
    FILE_CONFIG.PDF_TABLE_TITLE.x,
    FILE_CONFIG.PDF_TABLE_TITLE.y,
    {
      align: "justify",
    },
  );

  autoTable(doc, {
    ...FILE_CONFIG.PDF_TABLE,
    head: [header],
    body: rows,
    columnStyles,
  });

  return new Uint8Array(doc.output("arraybuffer"));
};

export const fileTableToCSV = (fileTable: FileTable): Uint8Array => {
  const { header, rows } = fileTable;

  const csvContent = [
    header.join(","),
    ...rows.map((row) => row.map((cell) => `"${cell}"`).join(",")),
  ].join("\n");

  const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
  const utf8Encoded = new TextEncoder().encode(csvContent);

  const result = new Uint8Array(bom.length + utf8Encoded.length);
  result.set(bom, 0);
  result.set(utf8Encoded, bom.length);

  return result;
};

export const downloadFile = (
  fileData: Uint8Array,
  fileName: string,
  fileType: MimeType = MIME_TYPES.TXT,
): void => {
  const blob = new Blob([fileData], { type: fileType });
  const url = URL.createObjectURL(blob);

  const a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();

  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};

export const createFileName = (
  name: string,
  extension: FileExtension,
): string => {
  const sanitizedText = name.replace(/\s+/g, "-");
  return `${sanitizedText}.${extension}`;
};
