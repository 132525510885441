import { TextField } from "@mui/material";
import { ActivityType } from "backend/api/bookings";
import { Platform } from "backend/api/platforms";
import SelectMember from "components/agenda/selectMember/SelectMember";
import SelectActivities from "components/discounts/SelectActivities";
import { Select } from "components/formInputs/Inputs";
import { PlatformSelector } from "components/platforms/PlatformSelector/PlatformSelector";
import { handleSelectChange } from "components/tabs/widget/WidgetTab.utils";
import { arePropsEqual, isArrayEmpty } from "helpers/helpers";
import { isNumber, isString } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import SelectStatus from "../SelectStatus/SelectStatus";
import {
  generateDateFilterOptions,
  generateStatusFilterOptions,
} from "./BookingsFilters.constants";
import {
  flattenBookings,
  getUniqueActivities,
  getUniquePlatforms,
} from "./BookingsFilters.helpers";
import { BookingsFiltersProps } from "./BookingsFilters.types";

const BookingsFilters = ({
  bookings,
  selectedActivities,
  setSelectedActivities,
  selectedPlatformsIds,
  setSelectedPlatformsIds,
  t,
  setSelectedSortFilter,
  selectedMembers,
  setSelectedMembers,
  selectedStatus,
  setSelectedStatus,
  setFrom,
  from,
  to,
  setTo,
  users,
}: BookingsFiltersProps) => {
  const [selectedActivityIds, setSelectedActivityIds] = useState<string[]>([]);

  const [activities, setActivities] = useState<ActivityType[]>([]);
  const [availablePlatforms, setAvailablePlatforms] = useState<Platform[]>([]);

  const { control, watch } = useForm({
    defaultValues: { date: "" },
  });

  const selectedSortFilter = watch("date");
  const filtersDateOptions = useMemo(() => generateDateFilterOptions(t), [t]);
  const statusOptions = useMemo(() => generateStatusFilterOptions(t), [t]);

  useEffect(() => {
    setSelectedSortFilter(selectedSortFilter);
  }, [selectedSortFilter]);

  useEffect(() => {
    const bookingsList = flattenBookings(bookings);

    setActivities(getUniqueActivities(bookingsList));
    setAvailablePlatforms(getUniquePlatforms(bookingsList));
  }, [bookings]);

  useEffect(() => {
    let updatedSelectedActvitiesIds: string[] = [];
    if (!isArrayEmpty(activities) && !isArrayEmpty(selectedActivities)) {
      updatedSelectedActvitiesIds = selectedActivities?.reduce(
        (acc: string[], selectedActivity: string) => {
          const activityId = activities.find(
            (activity: ActivityType) => activity.title === selectedActivity,
          )?.id;
          if (isNumber(activityId)) acc.push(activityId.toString());
          return acc;
        },
        [],
      );
    }
    setSelectedActivityIds(updatedSelectedActvitiesIds);
  }, [activities, selectedActivities]);

  useEffect(() => {
    const activityNames = selectedActivityIds.reduce(
      (acc: string[], selectedActivityId) => {
        const activityName = activities?.find(
          (activity: ActivityType) => activity.id == Number(selectedActivityId),
        )?.title;
        if (isString(activityName)) acc.push(activityName);
        return acc;
      },
      [],
    );
    setSelectedActivities(activityNames);
  }, [selectedActivityIds, activities]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        gap: 32,
        width: "100%",
      }}
    >
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))",
          gap: 5,
          width: 900,
        }}
      >
        <SelectMember
          selectedMemberIds={selectedMembers}
          users={users}
          handleSelectChange={(event: any) =>
            handleSelectChange(event, setSelectedMembers)
          }
          t={t}
          label={t("bookingFilter.staff")}
          resetSelection={true}
        />
        <SelectActivities
          selectedActivityIds={selectedActivityIds}
          activities={activities}
          handleSelectChange={(event: any) =>
            handleSelectChange(event, setSelectedActivityIds)
          }
          t={t}
          label={t("bookingFilter.activities")}
          resetSelection={true}
        />
        <SelectStatus
          multiple
          placeholder={t("bookingFilter.status")}
          options={statusOptions}
          onChange={(event: any) =>
            handleSelectChange(event, setSelectedStatus)
          }
          values={selectedStatus}
          id="selectStatus"
          name="selectStatus"
          resetSelection
        />
        <Select
          id={"date"}
          name={"date"}
          label={t("bookingFilter.date")}
          options={filtersDateOptions}
          control={control}
          error={""}
          t={t}
          resetSelection={true}
        />
        <PlatformSelector
          value={selectedPlatformsIds}
          onChange={setSelectedPlatformsIds}
          platforms={availablePlatforms}
          isMultiple
          isOffline
        />
      </div>
      <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <TextField
            label={t("bookingFilter.start")}
            type="date"
            onChange={(e) => setFrom(e.target.value)}
            sx={{
              width: 160,
              "@media (min-width:1700px)": {
                width: "100%",
              },
            }}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              type: "date",
              inputMode: "text",
              max: to,
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <TextField
            label={t("bookingFilter.end")}
            type="date"
            onChange={(e) => setTo(e.target.value)}
            sx={{
              width: 160,
              "@media (min-width:1700px)": {
                width: "100%",
              },
            }}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              type: "date",
              inputMode: "text",
              min: from,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(BookingsFilters, arePropsEqual);
