import React from "react";

const CalendarIcon = (props: { color?: string }) => {
  const { color } = props;

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.66699 4.79102C6.32533 4.79102 6.04199 4.50768 6.04199 4.16602V1.66602C6.04199 1.32435 6.32533 1.04102 6.66699 1.04102C7.00866 1.04102 7.29199 1.32435 7.29199 1.66602V4.16602C7.29199 4.50768 7.00866 4.79102 6.66699 4.79102Z" fill={color ?? "#888888"} />
      <path d="M13.333 4.79102C12.9913 4.79102 12.708 4.50768 12.708 4.16602V1.66602C12.708 1.32435 12.9913 1.04102 13.333 1.04102C13.6747 1.04102 13.958 1.32435 13.958 1.66602V4.16602C13.958 4.50768 13.6747 4.79102 13.333 4.79102Z" fill={color ?? "#888888"} />
      <path d="M7.08333 12.0839C6.975 12.0839 6.86667 12.0589 6.76667 12.0173C6.65833 11.9756 6.575 11.9172 6.49167 11.8422C6.34167 11.6839 6.25 11.4756 6.25 11.2506C6.25 11.1423 6.275 11.0339 6.31667 10.9339C6.35833 10.8339 6.41667 10.7423 6.49167 10.6589C6.575 10.5839 6.65833 10.5256 6.76667 10.4839C7.06667 10.3589 7.44167 10.4256 7.675 10.6589C7.825 10.8173 7.91667 11.0339 7.91667 11.2506C7.91667 11.3006 7.90833 11.3589 7.9 11.4173C7.89167 11.4673 7.875 11.5173 7.85 11.5673C7.83333 11.6173 7.80833 11.6672 7.775 11.7172C7.75 11.7589 7.70833 11.8006 7.675 11.8422C7.51667 11.9922 7.3 12.0839 7.08333 12.0839Z" fill={color ?? "#888888"} />
      <path d="M10.0003 12.0837C9.89199 12.0837 9.78366 12.0587 9.68366 12.0171C9.57532 11.9754 9.49199 11.917 9.40866 11.842C9.25866 11.6837 9.16699 11.4754 9.16699 11.2504C9.16699 11.142 9.19199 11.0337 9.23366 10.9337C9.27533 10.8337 9.33366 10.7421 9.40866 10.6587C9.49199 10.5837 9.57532 10.5254 9.68366 10.4837C9.98366 10.3504 10.3587 10.4254 10.592 10.6587C10.742 10.8171 10.8337 11.0337 10.8337 11.2504C10.8337 11.3004 10.8253 11.3587 10.817 11.4171C10.8087 11.4671 10.792 11.5171 10.767 11.5671C10.7503 11.6171 10.7253 11.667 10.692 11.717C10.667 11.7587 10.6253 11.8004 10.592 11.842C10.4337 11.992 10.217 12.0837 10.0003 12.0837Z" fill={color ?? "#888888"} />
      <path d="M12.9163 12.0837C12.808 12.0837 12.6997 12.0587 12.5997 12.0171C12.4913 11.9754 12.408 11.917 12.3247 11.842C12.2913 11.8004 12.258 11.7587 12.2247 11.717C12.1913 11.667 12.1663 11.6171 12.1497 11.5671C12.1247 11.5171 12.108 11.4671 12.0997 11.4171C12.0913 11.3587 12.083 11.3004 12.083 11.2504C12.083 11.0337 12.1747 10.8171 12.3247 10.6587C12.408 10.5837 12.4913 10.5254 12.5997 10.4837C12.908 10.3504 13.2747 10.4254 13.508 10.6587C13.658 10.8171 13.7497 11.0337 13.7497 11.2504C13.7497 11.3004 13.7413 11.3587 13.733 11.4171C13.7247 11.4671 13.708 11.5171 13.683 11.5671C13.6663 11.6171 13.6413 11.667 13.608 11.717C13.583 11.7587 13.5413 11.8004 13.508 11.842C13.3497 11.992 13.133 12.0837 12.9163 12.0837Z" fill={color ?? "#888888"} />
      <path d="M7.08333 15C6.975 15 6.86667 14.975 6.76667 14.9333C6.66667 14.8917 6.575 14.8333 6.49167 14.7583C6.34167 14.6 6.25 14.3833 6.25 14.1667C6.25 14.0583 6.275 13.95 6.31667 13.85C6.35833 13.7417 6.41667 13.65 6.49167 13.575C6.8 13.2667 7.36667 13.2667 7.675 13.575C7.825 13.7333 7.91667 13.95 7.91667 14.1667C7.91667 14.3833 7.825 14.6 7.675 14.7583C7.51667 14.9083 7.3 15 7.08333 15Z" fill={color ?? "#888888"} />
      <path d="M10.0003 15C9.78366 15 9.56699 14.9083 9.40866 14.7583C9.25866 14.6 9.16699 14.3833 9.16699 14.1667C9.16699 14.0583 9.19199 13.95 9.23366 13.85C9.27533 13.7417 9.33366 13.65 9.40866 13.575C9.71699 13.2667 10.2837 13.2667 10.592 13.575C10.667 13.65 10.7253 13.7417 10.767 13.85C10.8087 13.95 10.8337 14.0583 10.8337 14.1667C10.8337 14.3833 10.742 14.6 10.592 14.7583C10.4337 14.9083 10.217 15 10.0003 15Z" fill={color ?? "#888888"} />
      <path d="M12.9163 14.9994C12.6997 14.9994 12.483 14.9078 12.3247 14.7578C12.2497 14.6828 12.1913 14.5911 12.1497 14.4828C12.108 14.3828 12.083 14.2744 12.083 14.1661C12.083 14.0578 12.108 13.9494 12.1497 13.8494C12.1913 13.7411 12.2497 13.6494 12.3247 13.5744C12.5163 13.3828 12.808 13.2911 13.0747 13.3494C13.133 13.3578 13.183 13.3744 13.233 13.3994C13.283 13.4161 13.333 13.4411 13.383 13.4745C13.4247 13.4995 13.4663 13.5411 13.508 13.5744C13.658 13.7328 13.7497 13.9494 13.7497 14.1661C13.7497 14.3828 13.658 14.5994 13.508 14.7578C13.3497 14.9078 13.133 14.9994 12.9163 14.9994Z" fill={color ?? "#888888"} />
      <path d="M17.0837 8.19922H2.91699C2.57533 8.19922 2.29199 7.91589 2.29199 7.57422C2.29199 7.23255 2.57533 6.94922 2.91699 6.94922H17.0837C17.4253 6.94922 17.7087 7.23255 17.7087 7.57422C17.7087 7.91589 17.4253 8.19922 17.0837 8.19922Z" fill={color ?? "#888888"} />
      <path d="M13.3333 18.9577H6.66667C3.625 18.9577 1.875 17.2077 1.875 14.166V7.08268C1.875 4.04102 3.625 2.29102 6.66667 2.29102H13.3333C16.375 2.29102 18.125 4.04102 18.125 7.08268V14.166C18.125 17.2077 16.375 18.9577 13.3333 18.9577ZM6.66667 3.54102C4.28333 3.54102 3.125 4.69935 3.125 7.08268V14.166C3.125 16.5493 4.28333 17.7077 6.66667 17.7077H13.3333C15.7167 17.7077 16.875 16.5493 16.875 14.166V7.08268C16.875 4.69935 15.7167 3.54102 13.3333 3.54102H6.66667Z" fill={color ?? "#888888"} />
    </svg>
  );
};

export default CalendarIcon;
