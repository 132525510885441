import React from "react";

const SmsIcon = (props: { color?: string }) => {
  const { color } = props;

  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.334 14.6663H4.66732C2.23398 14.6663 0.833984 13.2663 0.833984 10.833V6.16634C0.833984 3.73301 2.23398 2.33301 4.66732 2.33301H11.334C13.7673 2.33301 15.1673 3.73301 15.1673 6.16634V10.833C15.1673 13.2663 13.7673 14.6663 11.334 14.6663ZM4.66732 3.33301C2.76065 3.33301 1.83398 4.25967 1.83398 6.16634V10.833C1.83398 12.7397 2.76065 13.6663 4.66732 13.6663H11.334C13.2407 13.6663 14.1673 12.7397 14.1673 10.833V6.16634C14.1673 4.25967 13.2407 3.33301 11.334 3.33301H4.66732Z" fill={color ?? "#657388"} />
      <path d="M7.9992 9.07998C7.4392 9.07998 6.87254 8.90665 6.4392 8.55331L4.35253 6.88665C4.1392 6.71331 4.0992 6.39998 4.27254 6.18665C4.44587 5.97331 4.75921 5.93332 4.97254 6.10665L7.0592 7.77332C7.56587 8.17998 8.42586 8.17998 8.93253 7.77332L11.0192 6.10665C11.2325 5.93332 11.5525 5.96665 11.7192 6.18665C11.8925 6.39998 11.8592 6.71998 11.6392 6.88665L9.55254 8.55331C9.12587 8.90665 8.5592 9.07998 7.9992 9.07998Z" fill={color ?? "#657388"} />
    </svg>
  );
};

export default SmsIcon;
