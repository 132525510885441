import { getAllPlatforms, Platform } from "backend/api/platforms";
import { InputCheckboxImageSelect } from "components/formInputs/Inputs";
import PlatformPlaceholder from "images/platform-placeholder.webp";
import { ChangeEvent, useEffect, useId, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { PLATFORM_SELECTOR_NAME } from "./PlatformSelector.constants";
import { generatePlatformOptions } from "./PlatformSelector.helpers";
import {
  PlatformOptionType,
  PlatformSelectorProps,
} from "./PlatformSelector.types";

export function PlatformSelector({
  id,
  placeholder,
  value,
  isOffline,
  isMultiple = false,
  platforms,
  errorMessage,
  onChange,
}: PlatformSelectorProps) {
  const defaultId = useId();
  const [currentPlatforms, setCurrentPlatforms] = useState<Platform[]>([]);

  const { t } = useTranslation("activities");

  const platformOptions: PlatformOptionType[] = useMemo(
    () => generatePlatformOptions(currentPlatforms),
    [currentPlatforms],
  );

  useEffect(() => {
    if (platforms?.length) {
      setCurrentPlatforms(platforms);
    }
  }, [platforms]);

  useQuery({
    queryKey: ["getAllPlatforms"],
    enabled: !isOffline,
    refetchOnWindowFocus: false,
    queryFn: getAllPlatforms,
    onSuccess(data) {
      setCurrentPlatforms(data);
    },
  });

  const handleCheckboxImageSelectChange = (
    e: ChangeEvent<{
      value: string[];
    }>,
  ) => {
    const selectedIds = e.target.value;
    onChange(selectedIds);
  };

  return (
    <InputCheckboxImageSelect
      id={id ?? defaultId}
      name={PLATFORM_SELECTOR_NAME}
      values={value}
      placeholder={placeholder ?? t("sellChannels")}
      imagePlaceholder={PlatformPlaceholder}
      options={platformOptions}
      multiple={isMultiple}
      error={errorMessage}
      onChange={handleCheckboxImageSelectChange}
      resetSelection
    />
  );
}
