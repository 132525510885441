import { BookingStatusEnum } from "helpers/constants";

export const generateDateFilterOptions = (t: (key: string) => string) => {
  return [
    { value: "bookings", label: t("sortByBooking") },
    { value: "order", label: t("sortByOrder") },
  ];
};

export const generateStatusFilterOptions = (t: (key: string) => string) => {
  return [
    {
      label: t(BookingStatusEnum.BOOKED),
      value: BookingStatusEnum.BOOKED,
      color: "#004643",
      bgColor: "#D5DB5E",
    },
    {
      label: t(BookingStatusEnum.PENDING),
      value: BookingStatusEnum.PENDING,
      color: "#000000",
      bgColor: "rgba(93, 93, 93, 0.4)",
    },
    {
      label: t(BookingStatusEnum.NO_SHOW),
      value: BookingStatusEnum.NO_SHOW,
      color: "#7E4501",
      bgColor: "rgba(255, 224, 118, 0.8)",
    },
    {
      label: t(BookingStatusEnum.CANCELED),
      value: BookingStatusEnum.CANCELED,
      color: "#9A0202",
      bgColor: "rgba(220, 132, 132, 0.35)",
    },
    {
      label: t(BookingStatusEnum.AMENDED),
      value: BookingStatusEnum.AMENDED,
      color: "#20408F",
      bgColor: "rgba(132, 157, 220, 0.35)",
    },
  ];
};
