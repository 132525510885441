import { useState } from "react";
import {
  savePayzenAccount,
  stripeConnect,
  paymentAccountConnectVerification,
} from "backend/api/stripeConnect";
import Loading from "components/general/Loading";
import { useUser } from "contexts/UserContext";
import { useMutation, useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { Message } from "helpers/helpers";
import AlertMessage from "components/general/AlertMessage";
import PayzenLogo from "images/icons/PayzenLogo";
import "./PaymentConnect.css";
import { InputText } from "components/formInputs/Inputs";
import { useForm } from "react-hook-form";
import { Button } from "@mui/material";
import StripeLogo from "images/icons/StripeLogo";
import { capitalize } from "lodash";
import { DashboardUrl, payzenAccountInfos } from "./PaymentConstants";
import { PayzenAccountData, payzenAccountSchema } from "./PaymentTypes";
import { zodResolver } from "@hookform/resolvers/zod";

const PaymentAccountConnect = () => {
  const { t } = useTranslation("profile");
  const { client } = useUser();
  const [alertMessage, setAlertMessage] = useState<Message>({
    type: "success",
    message: "",
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [
    connectPaymentPlatformProcessInProgress,
    setConnectPaymentPlatformProcessInProgress,
  ] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(payzenAccountSchema),
    defaultValues: payzenAccountInfos,
  });
  const {
    data: paymentAccountVerification,
    isLoading: isPaymentAccountConnectVerificationLoading,
    refetch: refetchPaymentAccountVerification,
  } = useQuery({
    queryKey: [`paymentConnectVerification`, client?.id],
    queryFn: () => paymentAccountConnectVerification(client?.id || ""),
    enabled: !!client?.id,
    refetchOnWindowFocus: false,
    retry: false,
  });

  const handleConnectStripe = async () => {
    setIsLoading(true);
    try {
      const connectUrl = await stripeConnect();
      window.location.href = connectUrl;
    } catch {
      setIsLoading(false);
      setAlertMessage({ message: t("errors.stripeConnect"), type: "error" });
    }
  };
  const mutateClientPayment = useMutation({
    mutationFn: savePayzenAccount,
    onSuccess: () => {
      setAlertMessage({
        type: "success",
        message: t("success.connectPayzenAccount"),
      });
      setConnectPaymentPlatformProcessInProgress(false);
      refetchPaymentAccountVerification();
    },
    onError: (error: any) => {
      console.log(error);
      setAlertMessage({
        type: "error",
        message: t(`errors.${error?.response?.data?.error}`),
      });
    },
  });

  const connectPayzen = async (data: PayzenAccountData) => {
    mutateClientPayment.mutate(data);
  };

  return (
    <div className="payment-account-connect-container">
      <AlertMessage
        alertMessage={alertMessage.message}
        setAlertMessage={setAlertMessage}
        type={alertMessage.type}
        t={t}
      />
      {(isLoading || isPaymentAccountConnectVerificationLoading) && <Loading />}
      {paymentAccountVerification?.accountCompleted ? (
        <div className="connected-account-container">
          <h2>{t("alreadyConnectPaymentAccount")}</h2>
          <a
            href={DashboardUrl[paymentAccountVerification.paymentPlatform]}
            target="_blank"
            rel="noopener noreferrer"
            className="payment-dashboard-link"
          >
            {t("dashboardReview")}{" "}
            {capitalize(paymentAccountVerification.paymentPlatform)}
          </a>
        </div>
      ) : (
        <>
          {connectPaymentPlatformProcessInProgress ? (
            <>
              <h2>{t("payzenRESTAPIKeys")}</h2>
              <form
                className="payzen-account-config-container"
                onSubmit={handleSubmit(connectPayzen)}
              >
                {Object.keys(payzenAccountInfos).map((payzenAccountInfo) => (
                  <InputText
                    label={t(`inputLabels.${payzenAccountInfo}`)}
                    name={payzenAccountInfo}
                    id={payzenAccountInfo}
                    control={control}
                    error={
                      errors[
                        payzenAccountInfo as keyof typeof payzenAccountInfos
                      ]?.message
                    }
                    t={t}
                  />
                ))}

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className="payzen-account-submit"
                >
                  {t("save")}
                </Button>
              </form>
            </>
          ) : (
            <div>
              <span>{t("paymentMethodConnexion")}</span>
              <div className="payment-platforms-container">
                <button
                  className="stripe-connect-btn"
                  onClick={handleConnectStripe}
                >
                  <StripeLogo />
                </button>
                <button
                  className="payzen-connect-btn"
                  onClick={() =>
                    setConnectPaymentPlatformProcessInProgress(true)
                  }
                >
                  <PayzenLogo />
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PaymentAccountConnect;
