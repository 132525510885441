export const RoleAccess = {
  Administrateur: [
    "dashboard",
    "profile",
    "agenda",
    "bookings",
    "discounts",
    "activities",
    "website",
    "alert",
    "emailing",
  ],
  Membre: [
    "agenda",
    "bookings",
    "discounts",
    "activities",
    "emailing",
    "website",
  ],
  Invité: ["agenda", "bookings", "emailing"],
};
