import { Avatar, Stack, Tooltip } from "@mui/material";
import { Options } from "./inputs.types";

export const getSelectedLabelsForPlaceholderDisplay = (
  selected: string[],
  options: Options,
): string => {
  return options
    .filter((option) => selected.includes(String(option.value)))
    .map((option) => option.label)
    .join(", ");
};

export const getSelectedImagesForPlaceholderDisplay = (
  selected: string[],
  options: Options,
  imagePlaceholder?: string,
) => {
  const selectedOptions = options.filter((option) =>
    selected.includes(String(option.value)),
  );
  return (
    <Stack direction={"row"} spacing={1}>
      {selectedOptions.slice(0, 3).map((option, index) => (
        <Tooltip key={index} title={option.label} placement="top">
          <Avatar
            src={option.avatar ?? imagePlaceholder}
            alt={option.label}
            variant="rounded"
            sx={{ width: 34, height: 34 }}
          />
        </Tooltip>
      ))}
      {selectedOptions.length > 3 && (
        <Avatar
          key={selectedOptions.length}
          sx={{ backgroundColor: "#E0E0E0", width: 34, height: 34 }}
        >
          +{selectedOptions.length - 3}
        </Avatar>
      )}
    </Stack>
  );
};

export const getSelectedStatusForPlaceholderDisplay = (
  selected: string[],
  options: Options,
) => {
  const selectedOptions = options.filter((option) =>
    selected.includes(String(option.value)),
  );
  return (
    <Stack direction={"row"} gap={5}>
      {selectedOptions.map((option, index) => (
        <p
          key={index}
          style={{
            margin: 0,
            color: option.color ?? "",
            backgroundColor: option.bgColor ?? "",
            padding: "2px 24px",
            borderRadius: 8,
          }}
        >
          {option.label}
        </p>
      ))}
    </Stack>
  );
};
