import { z } from "zod";

export type WidgetColorKeys =
  | "widgetBackgroundColor"
  | "textColorOnBackground"
  | "activityCardBackgroundColor"
  | "activityCardTextColor"
  | "actionBtnBackgroundColor"
  | "actionBtnTextColor";

const widgetColors = z.object({
  widgetBackgroundColor: z.string(),
  textColorOnBackground: z.string(),
  activityCardBackgroundColor: z.string(),
  activityCardTextColor: z.string(),
  actionBtnBackgroundColor: z.string(),
  actionBtnTextColor: z.string(),
});

const defaultT = (key: string) => key;

const style = z.object({
  fontFamily: z.string().optional(),
  colors: widgetColors.optional(),
});

const extraInfoSchema = (t = defaultT) =>
  z.object({
    question: z.string().min(5, { message: t("errors.extraInfo") }),
    answerRequired: z.boolean().default(false),
    answerOptions: z.array(z.string()).default([]).optional(),
  });

export const extraInfosSchema = (t = defaultT) =>
  z.array(extraInfoSchema(t)).max(3).optional();

export const widgetSchema = (t = defaultT) =>
  z.object({
    id: z.number().optional(),
    name: z.string().min(3, { message: t("errors.widgetName") }),
    activitiesIds: z
      .array(z.number())
      .min(1, { message: t("errors.activitiesIds") }),
    style: style.optional(),
    isDeleted: z.boolean().default(false),
    extraInfos: extraInfosSchema(t),
    discountCode: z.string().optional().nullable(),
    group_quotes_status: z.string().optional().nullable(),
    platformId: z.number().optional().nullable(),
  });

const schemaWithDefault = widgetSchema();

export type WidgetType = z.infer<typeof schemaWithDefault>;
export type WidgetColorsType = z.infer<typeof widgetColors>;

export interface SelectOption {
  value: string;
  label: string;
}

export interface GoogleFontFamilyType {
  family: string;
}

export enum GroupQuoteStatus {
  NOT_AVAILABLE = "NOT_AVAILABLE",
  ENABLED = "ENABLED",
  DISABLED = "DISABLED",
}
