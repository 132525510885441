import { useState } from "react";
import {
  Select,
  MenuItem,
  Checkbox,
  Button,
  Stack,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Option } from "components/formInputs/inputs.types";
import { getSelectedStatusForPlaceholderDisplay } from "components/formInputs/inputs.utils";

interface SelectStatusProps {
  disabled?: boolean;
  multiple: boolean;
  options: Option[];
  placeholder?: string;
  onChange: (event: any) => void;
  values: string[];
  resetSelection?: boolean;
  id: string;
  name: string;
  required?: boolean;
  error?: boolean;
}

export default function SelectStatus(props: SelectStatusProps) {
  const {
    multiple,
    disabled,
    values,
    placeholder,
    options,
    onChange,
    resetSelection,
    id,
    name,
    required,
    error,
  } = props;

  const [open, setOpen] = useState(false);
  const { t } = useTranslation("agenda");

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const handleChange = (event: any) => {
    const selectedValues = event.target.value.filter(Boolean);
    onChange({ target: { value: selectedValues } });
  };

  return (
    <Select
      name={name}
      id={id}
      multiple={multiple}
      displayEmpty
      disabled={disabled}
      value={values}
      required={required}
      error={error}
      renderValue={(selected) =>
        selected.length === 0 ? (
          <>{placeholder && <span>{placeholder}</span>}</>
        ) : (
          getSelectedStatusForPlaceholderDisplay(selected, options)
        )
      }
      onChange={handleChange}
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      sx={{
        borderRadius: "12px",
        margin: "8px",
        width: "calc(100% - 16px)",
        height: "60px",
      }}
    >
      {options.map((option, index) => (
        <MenuItem key={index} value={option.value} sx={{ fontSize: "14px" }}>
          <Checkbox
            checked={values.includes(String(option.value))}
            sx={{ paddingBlock: "0" }}
          />
          <p style={{ margin: 0, color: option.color ?? "", backgroundColor: option.bgColor ?? "", padding: "2px 24px", borderRadius: 8 }}>{option.label}</p>
        </MenuItem>
      ))}


      <Stack direction={"row"} justifyContent={"center"} gap={2} sx={{ paddingTop: 1 }}>
        {resetSelection && (
          <MenuItem
            value={t("remove_filter")}
            sx={{
              justifyContent: "center",
              color: "rgb(0, 134, 112)",
              fontWeight: 600,
            }}
            onClick={() => {
              onChange({ target: { value: [] } });
              handleClose();
            }}
          >
            {t("remove_filter")}
          </MenuItem>
        )}
        <MenuItem value={t("ok")} sx={{ padding: 0 }}>
          <Button variant="contained" color="info" onClick={handleClose}>
            {t("ok")}
          </Button>
        </MenuItem>
      </Stack>
    </Select>
  );
}
