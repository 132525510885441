import { Select, MenuItem, Button } from "@mui/material";
import { AddCircleRounded } from "@mui/icons-material";
import { EditableTableCellProps } from "./EditTableCell.type";
import { StyledTableCell } from "../AssignEmail/AssignEmail.style";
import {
  StyledFormControl,
  StyledIconButton,
  WrapperButton,
} from "./EditTableCell.style";

const EditableTableCell: React.FC<EditableTableCellProps> = ({
  activity,
  index,
  field,
  templateList,
  activities,
  setActivities,
  isLocked,
  t,
}) => {
  const isOpen = activity[`isOpenInput${field}`];
  const value = activity[`valueInput${field}`];

  const handleChange = (e: any) => {
    const newActivities = [...activities];
    newActivities[index][`valueInput${field}`] = e.target.value;
    setActivities(newActivities);
  };

  const handleOpen = () => {
    const newActivities = [...activities];
    newActivities[index][`isOpenInput${field}`] = true;
    setActivities(newActivities);
  };

  const handleClose = () => {
    const newActivities = [...activities];
    newActivities[index][`isOpenInput${field}`] = false;
    newActivities[index][`valueInput${field}`] = null;
    setActivities(newActivities);
  };

  return (
    <StyledTableCell>
      {isOpen ? (
        <StyledFormControl>
          <Select
            hiddenLabel
            labelId={`select-label-${field}`}
            id={`select-${field}`}
            value={value}
            onChange={handleChange}
            disabled={!isLocked}
          >
            {templateList.map((template: any, idx) => (
              <MenuItem key={idx} value={template.id}>
                {template.name}
              </MenuItem>
            ))}
            <WrapperButton>
              <Button
                variant="contained"
                color="info"
                onClick={handleClose}
                disabled={!isLocked}
              >
                {t("assignEmail.remove")}
              </Button>
            </WrapperButton>
          </Select>
        </StyledFormControl>
      ) : (
        <StyledIconButton
          aria-label="edit"
          disabled={!isLocked}
          onClick={handleOpen}
        >
          <AddCircleRounded />
        </StyledIconButton>
      )}
    </StyledTableCell>
  );
};

export default EditableTableCell;
