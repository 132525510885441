import { SelectChangeEvent } from "@mui/material";
import { InputCheckboxImageSelect } from "components/formInputs/Inputs";
import { User } from "types/auth";

interface SelectMemberProps {
  errors?: any;
  handleSelectChange: (event: SelectChangeEvent<(string | number)[]>) => void;
  id?: string;
  label: string;
  resetSelection?: boolean;
  selectedMemberIds: string[];
  t: any;
  users: User[];
}

export default function SelectMember({
  errors,
  handleSelectChange,
  id,
  label,
  resetSelection,
  selectedMemberIds,
  t,
  users,
}: SelectMemberProps) {
  const options = [
    ...(users?.map((user) => ({
      value: user.id.toString(),
      label: user.fullName,
      avatar: user.profilePictureUrl,
    })) || []),
  ];

  return (
    <InputCheckboxImageSelect
      multiple
      values={selectedMemberIds.map((id) => id.toString())}
      onChange={handleSelectChange}
      placeholder={label}
      id={id || "member_id"}
      name={"member_id"}
      options={options}
      required
      error={errors && errors.member_id}
      resetSelection={resetSelection}
    />
  );
}
