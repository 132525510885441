import React, { useEffect, useState } from "react";
import "./WidgetTab.css";
import SelectActivities from "components/discounts/SelectActivities";
import { useMutation, useQuery } from "react-query";
import { Activity, getActivities } from "backend/api/activities";
import { SelectChangeEvent, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  InputText,
  Select,
  UncontrolledCheckBox,
  UncontrolledSwitch,
} from "components/formInputs/Inputs";
import { useForm, Controller } from "react-hook-form";
import ColorSelector from "components/colorSelector/ColorSelector";
import {
  GroupQuoteStatus,
  SelectOption,
  widgetSchema,
  WidgetType,
} from "./WidgetTab.types";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  defaultWidgetColors,
  initialWidget,
  maxExtraInfos,
} from "./WidgetTab.constants";
import { isArray, isNumber } from "lodash";
import {
  arePropsEqual,
  copyToClipboard,
  isArrayEmpty,
  toastEmitter,
} from "helpers/helpers";
import {
  formatActivitiesIds,
  formatFontFamiliesOptions,
  getGoogleTopFontFamilies,
  getIframeCode,
  handleSelectChange,
  isWidgetNameAlreadyUsed,
} from "./WidgetTab.utils";
import WidgetCard from "components/widgetCard/WidgetCard";
import { getWidgets, upsertWidget } from "backend/api/widgets";
import Loader from "components/loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { WIDGETS_UPLOAD_SUCCESS } from "constants/widgetConstants";
import { RootState } from "store";
import SaveFormModal from "./saveFormModal/SaveFormModal";
import { useNavigationBlocker } from "./useNavigationBlocker";
import { Discount } from "backend/api/discounts";
import SelectDiscount from "components/discounts/SelectDiscount";
import { DELETED, TERMINATED } from "constants/discountConstants";
import { Link } from "react-router-dom";
import { ExtraInfoFields } from "./extraInfosFields/ExtraInfosFields";

const WidgetTab = () => {
  const [activeWidgetId, setActiveWidgetId] = useState<number | null>(null);
  const [activities, setActivities] = useState<Activity[]>([]);
  const [fontFamiliesOptions, setFontFamiliesOptions] = useState<
    SelectOption[]
  >([]);
  const [iframeCode, setIframeCode] = useState("");
  const [isSaveFormModalOpen, setIsSaveFormModalOpen] = useState(false);
  const [discountsOptions, setDiscountsOptions] = useState<SelectOption[]>([]);
  const [selectedDiscountCode, setSelectedDiscountCode] = useState<string>();
  const [isPinned, setIsPinned] = useState(false);
  const [isGroupQuote, setIsGroupQuote] = useState(false);
  const [showErrorGroupQuote, setShowErrorGroupQuote] = useState(false);
  const [saveDataChoice, setSaveDataChoice] = useState({
    save: false,
    quit: false,
    edit: false,
  });
  const { t } = useTranslation("website");
  const dispatch = useDispatch();
  const { navigationBlocker, setBlockCondition } = useNavigationBlocker();
  const widgets = useSelector(
    (state: RootState) => state?.widgets,
  ) as WidgetType[];

  useQuery({
    queryKey: ["getActivitiesWithDisabled"],
    queryFn: () => getActivities(true, true),
    refetchOnWindowFocus: false,
    onSuccess(data) {
      setActivities(
        data.filter((activity) => activity.informations.isDeleted !== true),
      );
    },
  });

  const { refetch: refetchWidgets, isLoading: widgetsLoading } = useQuery({
    queryKey: ["getWidgets"],
    queryFn: () => getWidgets(),
    onSuccess(data) {
      const widgets = data.filter(
        (widget: WidgetType) => !isNumber(widget.platformId),
      );
      dispatch({ type: WIDGETS_UPLOAD_SUCCESS, payload: widgets });
    },
    refetchOnWindowFocus: false,
  });

  const defaultValues = !isArrayEmpty(widgets) ? initialWidget : widgets[0];
  const [selectedActivityIds, setSelectedActivityIds] = useState<string[]>(
    formatActivitiesIds(defaultValues),
  );

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty: hasUnsavedChanges },
    setValue,
    getValues,
    watch,
    reset,
    setError,
  } = useForm<WidgetType>({
    resolver: zodResolver(widgetSchema(t)),
    defaultValues,
  });

  useEffect(() => {
    setBlockCondition(hasUnsavedChanges);
    if (navigationBlocker.state === "blocked") {
      setIsSaveFormModalOpen(true);
    }
  }, [navigationBlocker, hasUnsavedChanges]);

  useEffect(() => {
    reset();
  }, [defaultValues, reset]);

  useEffect(() => {
    //condition repeated to avoid typescript compile error
    if (navigationBlocker.state === "blocked") {
      const { quit, save } = saveDataChoice;
      if (quit) {
        navigationBlocker.proceed();
      } else if (save) {
        const formData = getValues();
        onSubmit(formData);
        navigationBlocker.proceed();
      }
    }
  }, [saveDataChoice, navigationBlocker]);

  useEffect(() => {
    if (widgets && !isArrayEmpty(widgets)) {
      const activeWidget =
        widgets.find(
          (widget: WidgetType) =>
            widget.id === activeWidgetId && !widget.isDeleted,
        ) ||
        widgets.find((widget: WidgetType) => !widget.isDeleted) ||
        initialWidget;

      if (activeWidget) {
        reset(activeWidget);
        setSelectedActivityIds(formatActivitiesIds(activeWidget));
        setIsGroupQuote(
          activeWidget.group_quotes_status === GroupQuoteStatus.ENABLED,
        );
        if (typeof activeWidget.discountCode === "string") {
          setIsPinned(true);
          setSelectedDiscountCode(activeWidget.discountCode);
          setValue("discountCode", activeWidget.discountCode);
        }
      }
    }
  }, [widgets, reset, activeWidgetId, setValue]);

  const handleChangeDiscount = (event: SelectChangeEvent<string>) => {
    const {
      target: { value },
    } = event;
    setSelectedDiscountCode(value);
    setValue("discountCode", value);
  };

  const handleChangePinned = (event: boolean) => {
    if (isArrayEmpty(discountsOptions) && !isPinned)
      return toastEmitter(t("noDiscountsAvailable"), "error");
    setIsPinned((prev) => !prev);
    if (event === true) {
      setValue("discountCode", undefined);
      setSelectedDiscountCode("");
    }
  };

  const handleChangeGroupQuotes = (event: boolean) => {
    if (watch("group_quotes_status") === GroupQuoteStatus.NOT_AVAILABLE) {
      setShowErrorGroupQuote(true);
    } else {
      setShowErrorGroupQuote(false);
      setIsGroupQuote((prev) => !prev);
      setValue(
        "group_quotes_status",
        event ? GroupQuoteStatus.DISABLED : GroupQuoteStatus.ENABLED,
      );
    }
  };

  const { mutate, isLoading: isWidgetUpdating } = useMutation({
    mutationFn: async (data: WidgetType) => {
      return await upsertWidget(data);
    },
    onSuccess: (result: any, widgetData: WidgetType) => {
      const isWidgetNew = !Boolean(widgetData.id);
      setActiveWidgetId(result.data?.id);

      const toastMsg = isWidgetNew
        ? `${result.data.name} ${t("createWidgetSuccessfully")}`
        : `${result.data.name} ${t("updateWidgetSuccessfully")}`;

      toastEmitter(toastMsg, "success");
      refetchWidgets();
    },
    onError: (error: any) => {
      console.error(error);
      toastEmitter(`${t("errors.updateWidget")} ${error.message}`, "error");
    },
  });

  const onSubmit = (data: WidgetType) => {
    if (isWidgetNameAlreadyUsed(data.name, widgets) && !widgetId) {
      // Set a custom error for the name field
      setError("name", {
        type: "manual",
        message: t("errors.widgetNameAlreadyUsed"),
      });
    } else mutate(data);
  };

  const extraInfos = watch("extraInfos");
  const widgetId = watch("id");

  useEffect(() => {
    setValue(
      "activitiesIds",
      selectedActivityIds.map((id) => Number(id)),
    );
  }, [selectedActivityIds, setValue]);

  useEffect(() => {
    if (!isArrayEmpty(selectedActivityIds)) {
      const activityDiscounts: Discount[] = (activities ?? [])
        .filter((activity) =>
          selectedActivityIds.includes(activity.id.toString()),
        )
        .flatMap((activity) => activity.discounts || [])
        .filter(
          (activity) =>
            activity.status !== DELETED && activity.status !== TERMINATED,
        )
        .reduce<Discount[]>((uniqueDiscounts, currentDiscount) => {
          if (
            !uniqueDiscounts.some(
              (discount) => discount.code === currentDiscount.code,
            )
          ) {
            uniqueDiscounts.push(currentDiscount);
          }
          return uniqueDiscounts;
        }, []);
      const tmpDiscountOptions: SelectOption[] = activityDiscounts.map(
        (discount: Discount) => ({
          value: discount.code,
          label: `${discount.name} - ${discount.discount_number}% | code: ${discount.code}`,
        }),
      );
      setDiscountsOptions(tmpDiscountOptions);
    }
  }, [selectedActivityIds]);

  useEffect(() => {
    const fetchFonts = async () => {
      const googleFonts = await getGoogleTopFontFamilies();
      const formattedFonts = formatFontFamiliesOptions(googleFonts);
      setFontFamiliesOptions(formattedFonts);
    };

    fetchFonts();
  }, []);

  useEffect(() => {
    setIframeCode(getIframeCode(widgetId));
  }, [widgetId, setIframeCode]);

  const changeWidgetForm = (widget: WidgetType) => {
    setSelectedActivityIds(formatActivitiesIds(widget));
    reset(widget);
    setIsGroupQuote(widget.group_quotes_status === GroupQuoteStatus.ENABLED);
    setShowErrorGroupQuote(false);
  };

  const addNewWidget = () => {
    changeWidgetForm(initialWidget);
  };

  const handleAddInfo = () => {
    if (isArray(extraInfos)) {
      if (extraInfos.length < maxExtraInfos) {
        const newExtraInfo = {
          question: "",
          answerRequired: false,
          answerOptions: [],
        };
        const updatedExtraInfos = [...extraInfos, newExtraInfo];
        setValue("extraInfos", updatedExtraInfos);
      } else {
        toastEmitter(t("errors.maxExtraInfos"), "error");
      }
    }
  };
  const RemoveAddInfo = (index: number) => {
    if (isArray(extraInfos)) {
      const updatedExtraInfos = [
        ...extraInfos.slice(0, index),
        ...extraInfos.slice(index + 1),
      ];
      setValue("extraInfos", updatedExtraInfos);
    }
  };
  const copyIframeCode = async () => {
    try {
      await copyToClipboard(iframeCode);
      toastEmitter(t("copyIframeCodeSuccessMsg"), "success");
    } catch (error) {
      toastEmitter(t("errors.copyIframeCodeErrorMsg"), "error");
    }
  };

  return (
    <>
      {isWidgetUpdating || widgetsLoading ? (
        <Loader />
      ) : (
        <div className="widget-tab">
          <div className="section widget-defintion">
            <span className="section-title">{t("widgetsSectionTitle")}</span>
            <div className="saved-widgets-container">
              {!isArrayEmpty(widgets) && (
                <>
                  {widgets?.map((widget: WidgetType) => (
                    <React.Fragment key={widget.id}>
                      {!widget.isDeleted && (
                        <WidgetCard
                          key={widget.id}
                          widget={widget}
                          changeWidgetForm={changeWidgetForm}
                          isActive={widget.id === widgetId}
                          refetchWidgets={refetchWidgets}
                        />
                      )}
                    </React.Fragment>
                  ))}
                </>
              )}
              <AddCircleIcon
                style={{
                  fill: "#4FD1C5",
                  cursor: "pointer",
                }}
                onClick={addNewWidget}
              />
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="widget-form">
              <InputText
                label={t("widgetNameLabel")}
                name="name"
                id="name"
                control={control}
                error={errors.name?.message}
                t={t}
              />
              <SelectActivities
                selectedActivityIds={selectedActivityIds}
                activities={activities}
                handleSelectChange={(event: any) =>
                  handleSelectChange(event, setSelectedActivityIds)
                }
                t={t}
                label={t("activitiesSelectorLabel")}
                errors={errors.activitiesIds}
              />
              <UncontrolledCheckBox
                label={t("isPinnedDiscount")}
                isActive={isPinned}
                uppercase={false}
                t={t}
                onChange={() => handleChangePinned(isPinned)}
              />
              {isPinned && (
                <SelectDiscount
                  t={t}
                  options={discountsOptions}
                  value={selectedDiscountCode}
                  onChange={handleChangeDiscount}
                />
              )}
              <button className="standard-btn" type="submit">
                {t("widgetCreationBtn")}
              </button>
            </form>
            <div className="iframe-section">
              <div>
                <UncontrolledCheckBox
                  label={t("groupQuotes")}
                  isActive={isGroupQuote}
                  uppercase={false}
                  t={t}
                  onChange={() => handleChangeGroupQuotes(isGroupQuote)}
                />
                {showErrorGroupQuote && (
                  <span className="error-message">
                    {t("groupQuoteError")}{" "}
                    <Link
                      to={"mailto:equipe.commerciale@hopleisure.com"}
                      className="error-message-link"
                    >
                      {t("groupQuoteErrorLink")}
                    </Link>
                  </span>
                )}
              </div>
              <span className="section-subtitle">{t("iframeCodeLabel")}</span>
              <span className="iframe-code">{iframeCode}</span>
              <button
                className="iframe-copy-btn"
                onClick={copyIframeCode}
                disabled={!Boolean(iframeCode.length)}
              >
                {t("codeCopyBtn")}
              </button>
            </div>
          </div>
          <form
            className="section widget-config"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="widget-style">
              <span className="section-title">
                {t("iframeStyleSectionTitle")}
              </span>
              <span className="section-subtitle">
                {t("iframeStyleSectionSubtitle")}
              </span>

              {!isArrayEmpty(fontFamiliesOptions) && (
                <Select
                  label={t("widgetFontFamily")}
                  name="style.fontFamily"
                  id="style.fontFamily"
                  control={control}
                  t={t}
                  options={fontFamiliesOptions}
                  error={errors.style?.fontFamily}
                />
              )}
              {Object.keys(defaultWidgetColors).map((key, index) => (
                <Controller
                  key={index}
                  name={`style.colors.${key}` as any}
                  control={control}
                  render={({ field }) => (
                    <>
                      {field.value && (
                        <ColorSelector
                          key={index}
                          initialColor={field.value as string}
                          label={t(`widgetColors.${key}`)}
                          updateWidgetColor={(color: string) =>
                            field.onChange(color)
                          }
                        />
                      )}
                    </>
                  )}
                />
              ))}
            </div>
            <div className="extra-infos-section">
              <span className="section-subtitle">{t("infosSectionTitle")}</span>
              <span>{t("infosSectionText")}</span>
              <div className="horizontal-info-group">
                <AddCircleOutlineIcon
                  style={{ color: "#4FD1C5", cursor: "pointer" }}
                  onClick={handleAddInfo}
                />
                <span>{t("addInfoBtnLabel")}</span>
              </div>
              <ExtraInfoFields
                control={control}
                errors={errors}
                setValue={setValue}
                extraInfos={extraInfos}
                onRemove={RemoveAddInfo}
                t={t}
              />
              <button className="standard-btn" type="submit">
                {t("saveDesignBtn")}
              </button>
            </div>
          </form>
          <SaveFormModal
            isSaveFormModalOpen={isSaveFormModalOpen}
            setIsSaveFormModalOpen={setIsSaveFormModalOpen}
            setSaveDataChoice={setSaveDataChoice}
          />
        </div>
      )}
    </>
  );
};

export default React.memo(WidgetTab, arePropsEqual);
