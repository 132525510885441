import {
  ActivityType,
  BookingType,
  TimeSlotBooking,
} from "backend/api/bookings";
import { Platform } from "backend/api/platforms";
import { getUniqueMap, mapValuesToArray } from "helpers/array.helpers";

export function flattenBookings(timeSlots: TimeSlotBooking[]): BookingType[] {
  return timeSlots.flatMap((ts) => ts.bookings);
}

export function getUniqueActivities(
  bookingsList: BookingType[],
): ActivityType[] {
  const activitiesList = bookingsList.map((b) => b.activity);
  const uniqueActivities = getUniqueMap<ActivityType, number>(
    activitiesList,
    (p) => p.id,
  );

  return mapValuesToArray(uniqueActivities);
}

export function getUniquePlatforms(bookingsList: BookingType[]): Platform[] {
  const platformsList = bookingsList.map((b) => b.platform);
  const uniquePlatforms = getUniqueMap<Platform, number>(
    platformsList,
    (p) => p.id,
  );

  return mapValuesToArray(uniquePlatforms);
}
