export enum DashboardUrl {
  STRIPE = "https://dashboard.stripe.com/login",
  PAYZEN = "https://secure.payzen.eu/vads-merchant/",
}

export const payzenAccountInfos = {
  payzenAccountId: "",
  testPassword: "",
  productionPassword: "",
  javascriptUrl: "",
  publicJavascriptKey: "",
};
