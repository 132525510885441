import {
  Route,
  Navigate,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";

import Agenda from "./pages/agenda/Agenda";
import Login from "./pages/Login";
import Register from "./pages/Register";
import PasswordResetPage from "./pages/PassWordResetPage";
import Error from "./pages/Error";
import Activities from "./pages/Activities";
import Bookings from "./pages/bookings/Bookings";
import Profile from "./pages/Profile";
import Discounts from "pages/Discounts";
import WebsitePage from "pages/website/WebsitePage";
import NewPasswordPage from "pages/NewPassword";
import { RoleAccess } from "helpers/role";
import Alert from "pages/alert/Alert";
import Emailing from "pages/emailing/Emailing";

function Router(): any {
  //changes made on the router to enable the navigation blocker when needed
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/" element={ConnectedRoute(<Agenda />, "agenda")} />
        <Route path="/login" element={NoConnectedRoute(<Login />)} />
        <Route path="/register" element={NoConnectedRoute(<Register />)} />
        <Route path="/passwordResetPage" element={<PasswordResetPage />} />
        <Route path="/password" element={<NewPasswordPage />} />
        <Route path="/agenda" element={ConnectedRoute(<Agenda />, "agenda")} />
        <Route
          path="/activities/*"
          element={ConnectedRoute(<Activities />, "activities")}
        />
        <Route
          path="/discounts"
          element={ConnectedRoute(<Discounts />, "discounts")}
        />
        <Route
          path="/bookings"
          element={ConnectedRoute(<Bookings />, "bookings")}
        />
        <Route
          path="/profile"
          element={ConnectedRoute(<Profile />, "profile")}
        />
        <Route path="/alert" element={ConnectedRoute(<Alert />, "alert")} />
        <Route
          path="/website"
          element={ConnectedRoute(<WebsitePage />, "website")}
        />
        <Route
          path="/facturation"
          element={ConnectedRoute(<Profile />, "profile")}
        />
        <Route
          path="/emailing"
          element={ConnectedRoute(<Emailing />, "emailing")}
        />
        <Route
          path="/assign-emailing"
          element={ConnectedRoute(<Emailing />, "emailing")}
        />
        <Route path="*" element={<Error />} />
      </>,
    ),
  );

  return <RouterProvider router={router} />;
}

function NoConnectedRoute(Element: any) {
  const accessToken = localStorage.getItem("accessToken");
  return accessToken ? <Navigate to="/agenda" /> : Element;
}

function ConnectedRoute(Element: any, page: string) {
  const role = localStorage.getItem("role");
  const accessToken = localStorage.getItem("accessToken");
  const roleAccess = role ? RoleAccess[role as keyof typeof RoleAccess] : null;
  return accessToken && roleAccess?.includes(page) ? (
    Element
  ) : accessToken && !roleAccess ? (
    <Navigate to="/not-found" />
  ) : (
    <Navigate to="/login" />
  );
}
export default Router;
