import { TimeSlotBooking } from "backend/api/bookings";
import { isArrayEmpty } from "helpers/helpers";
import CalendarIcon from "images/icons/CalendarIcon";
import moment from "moment";
import React from "react";
import { User } from "types/auth";
import BookingItem from "./BookingItem";
import "./TimeSlotBox.css";

type TimeSlotBoxProps = {
  booking: TimeSlotBooking;
  users: User[];
  t: any
};

const TimeSlotBox: React.FC<TimeSlotBoxProps> = ({ booking, users, t }) => {
  const formatDateRange = (dateRange: string): string => {
    const [start, end] = dateRange.split(" - ");
    const startDate = moment(start, "YYYY-MM-DD HH:mm");
    const endDate = moment(end, "YYYY-MM-DD HH:mm");
    const formattedDate = startDate.format("D MMMM YYYY");
    const formattedTimeRange = `${startDate.format("HH:mm")} - ${endDate.format("HH:mm")}`;
    return `${formattedDate} | ${formattedTimeRange}`;
  };

  return (
    <div className="booking-card">
      <div className="booking-header">
        <CalendarIcon />
        <p>{formatDateRange(booking.timeSlot)}</p>
      </div>
      {!isArrayEmpty(booking.bookings) &&
        booking.bookings.map((item, i) => <BookingItem key={i} users={users} item={item} t={t} />)}
    </div>
  );
};

export default TimeSlotBox;
