import React, { useState } from "react";
import {
  Switch,
  FormLabel,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import "../../../pages/emailing/Emailing.css";
import { useMutation, useQuery } from "react-query";
import { getAllClientActivitiesNameAndIds } from "backend/api/activities";
import {
  getAlertEmailTemplate,
  updateAlertEmailTemplate,
  updateClientSetting,
} from "backend/api/emailing";
import EditableTableCell from "../EditTableCell/EditTableCell";
import {
  StyledButton,
  StyledIconButton,
  StyledTableCell,
  StyledTableContainer,
  StyledTableHead,
} from "./AssignEmail.style";
import { Activity, AssignEmailProps } from "./AssignEmail.type";

const AssignEmail: React.FC<AssignEmailProps> = ({
  setAlertMessage,
  listTemplatesReview,
  listTemplatesReminder,
  listTemplatesConfirmation,
  listTemplatesCancellation,
  listTemplatesLoyalty,
  t,
}) => {
  const [isLocked, setIsLocked] = useState(false);
  const [activities, setActivities] = useState<Activity[]>([]);
  const [sendByEmail, setSendByEmail] = useState(false);
  const [sendBySms, setSendBySms] = useState(false);

  const { refetch: refetchActivities } = useQuery({
    queryKey: ["activities"],
    queryFn: () => getAllClientActivitiesNameAndIds(),
    refetchOnWindowFocus: false,
    onSuccess(data) {
      const newActivities = data.map((activity: any) => ({
        id: activity?.id,
        name: activity?.name,
        isOpenInputConfirm: activity?.reservationtemplateid ? true : false,
        isOpenInputCancel: activity?.cancellationtemplateid ? true : false,
        isOpenInputReminder: activity?.remindertemplateid ? true : false,
        isOpenInputReview: activity?.reviewreqtemplateid ? true : false,
        isOpenInputLoyalty: activity?.loyaltytemplateid ? true : false,
        valueInputConfirm: activity?.reservationtemplateid,
        valueInputCancel: activity?.cancellationtemplateid,
        valueInputReminder: activity?.remindertemplateid,
        valueInputReview: activity?.reviewreqtemplateid,
        valueInputLoyalty: activity?.loyaltytemplateid,
      }));
      setActivities(newActivities);
    },
  });

  const { data: alertSetting, refetch: refecthAlertSetting } = useQuery({
    queryKey: ["alert-settings"],
    queryFn: () => getAlertEmailTemplate(),
    refetchOnWindowFocus: false,
    onSuccess(data) {
      setSendByEmail(data?.emailSwitcher);
      setSendBySms(data?.smsSwitcher);
    },
  });

  const { mutate: mutateUpdateSetting, isLoading: isLoadingUpdateSetting } =
    useMutation({
      mutationFn: (data: any) => updateClientSetting(data),
      onSuccess: (result: any) => {
        setAlertMessage({ type: "success", message: result.message });
        refetchActivities();
        setIsLocked(false);
      },
      onError: (error: any) => {
        setAlertMessage({ type: "error", message: error.message });
      },
    });

  const { mutate: mutateUpdateAlertSetting } = useMutation({
    mutationFn: (data: any) => updateAlertEmailTemplate(data),
    onSuccess: (result: any) => {
      setAlertMessage({ type: "success", message: result.message });
      refecthAlertSetting();
      setIsLocked(false);
    },
    onError: (error: any) => {
      setAlertMessage({ type: "error", message: error.message });
    },
  });

  const handleUpdateSetting = () => {
    const newActivities = activities.map((item: Activity) => ({
      activityId: item.id,
      reservationTemplateId: Number(item.valueInputConfirm) || null,
      cancellationTemplateId: Number(item.valueInputCancel) || null,
      reminderTemplateId: Number(item.valueInputReminder) || null,
      reviewReqTemplateId: Number(item.valueInputReview) || null,
      loyaltyReqTemplateId: Number(item.valueInputLoyalty) || null,
    }));

    const payload = {
      data: newActivities,
    };

    if (alertSetting.emailSwitcher !== sendByEmail) {
      const payloadAlert = {
        switcher: sendByEmail,
      };
      mutateUpdateAlertSetting(payloadAlert);
    }
    mutateUpdateSetting(payload);
  };

  return (
    <div className="card">
      <StyledIconButton
        aria-label="close"
        color="primary"
        onClick={() => {
          setIsLocked(!isLocked);
        }}
      >
        {isLocked ? (
          <LockOpenIcon fontSize={"small"} color={"secondary"} />
        ) : (
          <LockIcon fontSize={"small"} color={"primary"} />
        )}
      </StyledIconButton>

      <h2 className="title">{t("assignEmail.title")}</h2>
      <div className="space-switch">
        <div className="switch">
          <Switch
            disabled={!isLocked}
            value={sendByEmail}
            checked={sendByEmail}
            onChange={(e) => setSendByEmail(e.target.checked)}
          />
          <FormLabel
            sx={{
              color: "#004346",
            }}
          >
            {t("assignEmail.checkboxEmail")}
          </FormLabel>
        </div>
        {/* WILL BE ADDED LATER */}
        {/* <div className="switch">
          <Switch
            disabled={!isLocked}
            value={sendBySms}
            checked={sendBySms}
            onChange={(e) => setSendBySms(e.target.checked)}
          />
          <FormLabel
            sx={{
              color: "#004346",
            }}
          >
            {t("assignEmail.checkboxSMS")}
          </FormLabel>
        </div> */}
      </div>

      <StyledTableContainer>
        <Table>
          <StyledTableHead>
            <TableRow>
              <TableCell>{t("assignEmail.activity")}</TableCell>
              <StyledTableCell>{t("assignEmail.confirmation")}</StyledTableCell>
              <StyledTableCell>{t("assignEmail.cancellation")}</StyledTableCell>
              {/* <StyledTableCell>{t("assignEmail.reminder")}</StyledTableCell>
              <StyledTableCell>{t("assignEmail.review")}</StyledTableCell>
              <StyledTableCell>{t("assignEmail.loyalty")}</StyledTableCell> */}
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {activities.map((activity, index) => (
              <TableRow key={index}>
                <TableCell>{activity.name}</TableCell>
                <EditableTableCell
                  activity={activity}
                  index={index}
                  field="Confirm"
                  templateList={listTemplatesConfirmation}
                  activities={activities}
                  setActivities={setActivities}
                  isLocked={isLocked}
                  t={t}
                />
                <EditableTableCell
                  activity={activity}
                  index={index}
                  field="Cancel"
                  templateList={listTemplatesCancellation}
                  activities={activities}
                  setActivities={setActivities}
                  isLocked={isLocked}
                  t={t}
                />
                {/* <EditableTableCell
                  activity={activity}
                  index={index}
                  field="Reminder"
                  templateList={listTemplatesReminder}
                  activities={activities}
                  setActivities={setActivities}
                  isLocked={isLocked}
                  t={t}
                />
                <EditableTableCell
                  activity={activity}
                  index={index}
                  field="Review"
                  templateList={listTemplatesReview}
                  activities={activities}
                  setActivities={setActivities}
                  isLocked={isLocked}
                  t={t}
                />
                <EditableTableCell
                  activity={activity}
                  index={index}
                  field="Loyalty"
                  templateList={listTemplatesLoyalty}
                  activities={activities}
                  setActivities={setActivities}
                  isLocked={isLocked}
                  t={t}
                /> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>

      <p className="note">{t("assignEmail.desc")}</p>
      <div className="btn-right">
        <StyledButton
          variant="contained"
          color="info"
          disabled={!isLocked || isLoadingUpdateSetting}
          onClick={() => handleUpdateSetting()}
        >
          {t("assignEmail.saveTemplate")}
        </StyledButton>
      </div>
    </div>
  );
};

export default AssignEmail;
