import axios from "axios";
import { z } from "zod";
import { getAuthorizedConfig } from "../../helpers/helpers";
import { Activity } from "./activities";

export const client = z.object({
  id: z.number(),
  createdAt: z.date(),
  email: z.string(),
  contactEmail: z.string(),
  companyName: z.string(),
  contactPhoneNumber: z.string(),
  companyAddress: z.string(),
  logoUrl: z.string(),
  currencyCode: z.string(),
  bankName: z.string().nullable().optional(),
  iban: z.string().nullable().optional(),
  bic: z.string().nullable().optional(),
  siren: z.string().nullable().optional(),
  vat: z.string().nullable().optional(),
});

export type Client = z.infer<typeof client>;

export async function getClient(): Promise<Client> {
  const clientId = localStorage.getItem("clientId");
  let config = getAuthorizedConfig("GET");
  config["url"] = `${process.env.REACT_APP_API_URL}/clients/${clientId}`;
  const response = await axios.request(config);
  const result = response.data;
  return result;
}

// The PATCH service for updating client details
export async function updateClient(
  clientId: number | string,
  updateData: Partial<Client>,
): Promise<Client> {
  // Obtain an authorization header or other config
  let config = getAuthorizedConfig("PATCH");

  // Set the appropriate URL and the HTTP method
  config["url"] = `${process.env.REACT_APP_API_URL}/clients/${clientId}`;
  config["data"] = updateData;

  // Make the API call with axios
  const response = await axios.request(config);

  // Return the updated client details from the response
  return response.data;
}

export type ActivityAlert = {
  activities: Activity;
  activity_alerts: Alert;
};

export async function getAlert(): Promise<ActivityAlert[]> {
  let config = getAuthorizedConfig("GET");

  config["url"] = `${process.env.REACT_APP_API_URL}/clients/alerts/notif`;

  const response = await axios.request(config);
  return response.data.data;
}

export const alertSchema = z.object({
  id: z.number().optional(),
  activityId: z.number(),
  isSetting: z.boolean(),
  gauge: z.number(),
  priceIds: z.array(z.union([z.number(), z.boolean()])),
  alertStaff: z.boolean(),
  alertVisitor: z.boolean(),
  nonAlertStaff: z.boolean(),
  autoCancelBooking: z.boolean(),
  nonHoursReminder: z.number(),
});

export type Alert = z.infer<typeof alertSchema>;

export async function upsertAlert(data: Alert[]) {
  let config = getAuthorizedConfig("PATCH");

  config["url"] = `${process.env.REACT_APP_API_URL}/clients/alerts/upsert`;
  config["data"] = data;

  const response = await axios.request(config);
  return response.data;
}
