import { ActivityPrice } from "backend/api/activities";
import { isArrayEmpty } from "helpers/helpers";
import { PriceForm } from "./bookingFormValidator";

export const getTicketsNumber = (prices: PriceForm[]): number => {
  if (isArrayEmpty(prices)) return 0;
  return prices.reduce((ticketsNumber: number, price: PriceForm) => {
    ticketsNumber += price.number;
    return ticketsNumber;
  }, 0);
};

export function getBookingPrice(
  priceData: ActivityPrice,
  quantity: number,
): number | null {
  if (!priceData) {
    return null;
  }
  const { tieredPricingEnabled, tiers, price } = priceData;
  if (tieredPricingEnabled && tiers) {
    let tierPrice = price;

    for (const tier of tiers) {
      if (quantity >= tier.minQuantity && quantity <= tier.maxQuantity) {
        tierPrice = tier.price;
        break;
      }
    }
    return tierPrice;
  }
  return price;
}
