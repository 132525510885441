import { ActivityAlert } from "backend/api/clients";
import {
  Checkbox,
  ControlledSwitch,
  InputText,
} from "components/formInputs/Inputs";
import { ActivityTypeEnum } from "helpers/constants";
import React, { Fragment, useMemo } from "react";
import { Control, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface AlertBodyItemProps {
  control: Control<{ activities: ActivityAlert[] }>;
  index: number;
}

export default function AlertBodyItem({ control, index }: AlertBodyItemProps) {
  const { t } = useTranslation("alert");
  const { t: tActivity } = useTranslation("activities");
  const values = useWatch({ control });

  const { activities, activity_alerts } = useMemo(
    () => values.activities?.[index] ?? {},
    [values, index],
  );

  const maxGauge = useMemo(() => {
    const bookingSettings = activities?.informations?.bookingSettings;
    switch (bookingSettings?.type) {
      case ActivityTypeEnum.INDIVIDUAL:
        return bookingSettings.maxCapacity;
      case ActivityTypeEnum.GROUP:
        return bookingSettings.maxGroups;
      default:
        return 0;
    }
  }, [activities?.informations?.bookingSettings]);

  const isActive = useMemo(() => activity_alerts?.isSetting, [activity_alerts]);

  return (
    <tr>
      <td className="title">{activities?.informations?.name}</td>
      <td align="center">
        <ControlledSwitch
          control={control}
          id={`activities[${index}].activity_alerts.isSetting`}
          name={`activities[${index}].activity_alerts.isSetting`}
          label={""}
          labelClassName="label"
          t={tActivity}
          uppercase={false}
          style={{ margin: "0 auto" }}
        />
        {isActive && (
          <Fragment>
            <InputText
              control={control}
              id={`activities[${index}].activity_alerts.gauge`}
              name={`activities[${index}].activity_alerts.gauge`}
              inputType="number"
              t={t}
              size="small"
              max={maxGauge}
            />
            {activities?.informations?.bookingSettings?.type ===
              ActivityTypeEnum.INDIVIDUAL && (
              <p className="label">
                / {activities?.informations?.bookingSettings.maxCapacity}{" "}
                {t("person")}(s)
              </p>
            )}
            {activities?.informations?.bookingSettings?.type ===
              ActivityTypeEnum.GROUP && (
              <p className="label">
                / {activities?.informations?.bookingSettings.maxGroups}{" "}
                {t("group")}
                (s)
              </p>
            )}
          </Fragment>
        )}
      </td>
      <td>
        {isActive &&
          activities?.prices?.map((price, j) => (
            <Checkbox
              key={`activities[${index}].activity_alerts.priceIds[${j}]`}
              control={control}
              id={`activities[${index}].activity_alerts.priceIds[${j}]`}
              name={`activities[${index}].activity_alerts.priceIds[${j}]`}
              label={tActivity(`priceName.${price.type}`, {
                defaultValue: price.name,
              })}
              labelClassName="label"
              t={tActivity}
              uppercase={false}
            />
          ))}
      </td>
      <td>
        {isActive && (
          <Fragment>
            <ControlledSwitch
              control={control}
              id={`activities[${index}].activity_alerts.alertStaff`}
              name={`activities[${index}].activity_alerts.alertStaff`}
              label={t(`concernedStaff`)}
              labelClassName="label"
              t={tActivity}
              uppercase={false}
            />
            <ControlledSwitch
              control={control}
              id={`activities[${index}].activity_alerts.alertVisitor`}
              name={`activities[${index}].activity_alerts.alertVisitor`}
              label={t(`concernedClient`)}
              labelClassName="label"
              t={tActivity}
              uppercase={false}
            />
          </Fragment>
        )}
      </td>
      <td>
        {isActive && (
          <Fragment>
            {(activity_alerts?.nonAlertStaff ||
              activity_alerts?.autoCancelBooking) && (
              <InputText
                control={control}
                id={`activities[${index}].activity_alerts.nonHoursReminder`}
                name={`activities[${index}].activity_alerts.nonHoursReminder`}
                inputType="number"
                t={t}
                size="small"
                label={t(`sendDate`)}
              />
            )}
            <ControlledSwitch
              control={control}
              id={`activities[${index}].activity_alerts.nonAlertStaff`}
              name={`activities[${index}].activity_alerts.nonAlertStaff`}
              label={t(`concernedStaff`)}
              labelClassName="label"
              t={tActivity}
              uppercase={false}
            />
            <ControlledSwitch
              control={control}
              id={`activities[${index}].activity_alerts.autoCancelBooking`}
              name={`activities[${index}].activity_alerts.autoCancelBooking`}
              label={t(`cancelSlot`)}
              labelClassName="label"
              t={tActivity}
              uppercase={false}
            />
          </Fragment>
        )}
      </td>
    </tr>
  );
}
