import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Avatar, AvatarGroup, Chip } from "@mui/material";
import { BookingType } from "backend/api/bookings";
import { BookingStatusEnum, categoryImageMapping } from "helpers/constants";
import { isArrayEmpty } from "helpers/helpers";
import CallIcon from "images/icons/CallIcon";
import SmsIcon from "images/icons/SmsIcon";
import React, { useMemo, useState } from "react";
import { User } from "types/auth";
import "./TimeSlotBox.css";

type BookingItemProps = {
  item: BookingType;
  users: User[]
  t: any;
};

const BookingItem: React.FC<BookingItemProps> = ({ item, users, t }) => {
  const [expand, setExpand] = useState(false);

  const assignedStaff = useMemo(() => {
    return users.filter(user => item.assignedUsers.some(selected => selected.id === user.id));
  }, [users, item.assignedUsers]);

  const { color, backgroundColor } = useMemo(() => {
    switch (item.status) {
      case BookingStatusEnum.CANCELED:
      case BookingStatusEnum.DELETED_BY_CLIENT:
        return { color: "#9A0202", backgroundColor: "rgba(220, 132, 132, 0.35)" };
      case BookingStatusEnum.NO_SHOW:
        return { color: "#7E4501", backgroundColor: "rgba(255, 224, 118, 0.8)" };
      case BookingStatusEnum.PENDING:
        return { color: "#000000", backgroundColor: "rgba(93, 93, 93, 0.4)" };
      case BookingStatusEnum.AMENDED:
        return { color: "#20408F", backgroundColor: "rgba(132, 157, 220, 0.35)" };
      default:
        return { color: "#004643", backgroundColor: "#D5DB5E" };
    }
  }, [item.status]);

  const totalAmount = useMemo(() => {
    return item.prices.reduce((sum, price) => sum + price.bookingPrice.totalAmountWithDiscount, 0);
  }, [item.prices]);

  const displayStatus = useMemo(() => {
    return [BookingStatusEnum.DELETED_BY_CLIENT].includes(item.status) ? BookingStatusEnum.CANCELED : item.status
  }, [item.status]);

  return (
    <div className="booking-item">
      <div className="booking-item-header">
        <div className="booking-item-info">
          <Chip
            icon={categoryImageMapping(item.category.name)}
            label={
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  color: "#33353B"
                }}
              >
                <strong>{item.activity.title}</strong>
              </div>
            }
            sx={{ width: "fit-content", border: "none" }}
            color={"success"}
            variant={"outlined"}
            size="medium"
          />
          <div className="contact-info">
            <p>{item.visitor.full_name}</p>
            <p className="dot-separator"></p>
            <p className="person-count">{item.total_unit} {t("person")}</p>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 6, alignItems: "center" }}>
            <div className="contact-detail">
              <SmsIcon />
              <p>{item.visitor.email}</p>
            </div>
            <p className="dot-separator"></p>
            <div className="contact-detail">
              <CallIcon />
              <p>{item.visitor.phone_number}</p>
            </div>
          </div>
        </div>
        <div style={{ width: "25%" }}>
          <div style={{ display: "flex", justifyContent: "end", padding: "12px 16px" }}>
            <p style={{ margin: 0, color, backgroundColor, padding: "2px 24px", borderRadius: 8 }}>{t(displayStatus)}</p>
          </div>
          {!isArrayEmpty(assignedStaff) && (
            <div className="booking-staff">
              <p>{t("staff")}</p>
              <AvatarGroup variant="rounded" max={4}>
                {assignedStaff.map((staff, index) => (
                  <Avatar variant="rounded" key={index} alt={staff.fullName} src={staff.profilePictureUrl} />
                ))}
              </AvatarGroup>
            </div>
          )}
        </div>
      </div>
      {!!expand && (
        <>
          <div className="booking-item-details">
            <div className="booking-summary">
              <p><strong>{t("externalReference")}</strong> : {item.order.external_reference ?? "-"}</p>
              <p>{t("totalReservation")}</p>
              <p className="total-price">{totalAmount} € <span className="payment-method">{t("paidVia")} {item.platform.name}</span></p>
            </div>
            {!isArrayEmpty(item.extra_infos) && (
              <div style={{ width: "100%" }}>
                <p>{t("additionalInformation")}</p>
                {item.extra_infos.map((info, key) => (
                  <p key={key} style={{ margin: 0 }}>{info.question} : {info.answer}</p>
                ))}
              </div>
            )}
          </div>
          <div className="pricing-container">
            {!isArrayEmpty(item.prices) && item.prices.map((price, key) => (
              <div key={key} className="price-badge">
                <p>{price.number_of_unit} {t("tarifs")} {price.name}</p>
              </div>
            ))}
          </div>
        </>
      )}
      <button onClick={() => setExpand(!expand)} className="see-less-button">
        {expand ? (
          <>
            {t("seeLess")}
            <ExpandLess />
          </>
        ) : (
          <>
            {t("seeMore")}
            <ExpandMore />
          </>
        )}
      </button>
    </div>
  );
};

export default BookingItem
