import { z } from "zod";

export enum PaymentPlatformEnum {
  STRIPE = "STRIPE",
  PAYZEN = "PAYZEN",
}
export interface PayzenAccountData {
  payzenAccountId: string;
  testPassword: string;
  productionPassword: string;
  javascriptUrl: string;
  publicJavascriptKey: string;
}
export interface PaymentConnectVerificationOutput {
  accountCompleted: boolean;
  paymentPlatform: PaymentPlatformEnum;
}

export const payzenAccountSchema = z.object({
  payzenAccountId: z.string().min(5),
  testPassword: z.string().min(5),
  productionPassword: z.string().min(5),
  javascriptUrl: z.string().min(5),
  publicJavascriptKey: z.string().min(5),
});
