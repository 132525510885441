import { BookingType, TimeSlotBooking, Visitor } from "backend/api/bookings";
import { DATE_FORMATS } from "constants/dateConstants";
import { ActivityTypeEnum } from "helpers/constants";
import { formatDate, parseDate } from "helpers/date.helpers";
import { Moment } from "moment";
import { FileTable, FileTableRow } from "types/common";

const visitorToFileDataRow = (
  visitor?: Visitor,
  singleColumn?: boolean,
  placeholder: string = "-",
) => {
  if (visitor) {
    return singleColumn
      ? [`${visitor.full_name},\n${visitor.email},\n${visitor.phone_number}`]
      : [visitor.full_name, visitor.email, visitor.phone_number];
  } else {
    return singleColumn ? [placeholder] : ["", "", ""];
  }
};
const generateVisitorFileDataHeader = (
  t: (key: string) => string,
  singleColumn?: boolean,
) => {
  return singleColumn
    ? [t("visitor")]
    : [t("visitorName"), t("visitorEmail"), t("visitorPhone")];
};

const bookingsToFileDataRow = (
  booking: BookingType,
  t: (key: string) => string,
  placeholder: string,
  forCsv?: boolean,
): FileTableRow => {
  const orderDate = booking.order
    ? formatDate(parseDate(booking.order.created_at) as Moment)
    : placeholder;
  const bookingDate = booking.from
    ? formatDate(parseDate(booking.from) as Moment)
    : placeholder;
  const bookingStartTime = booking.from
    ? formatDate(parseDate(booking.from) as Moment, DATE_FORMATS.HOUR_ONLY)
    : placeholder;

  const visitor = visitorToFileDataRow(booking.visitor, !forCsv, placeholder);

  // TODO: Check if the price.name is the price type
  const bookingPrices =
    booking.prices[0].name === ActivityTypeEnum.GROUP
      ? booking.prices[0].name
      : booking.prices.map((p) => `${p.number_of_unit} x ${p.name}`).join(", ");

  const totalBookingPrice = booking.prices.reduce(
    (totalPrice, price) =>
      totalPrice + (price.bookingPrice.totalAmountWithDiscount ?? 0),
    0,
  );

  const totalBookingDiscounts = booking.prices.reduce(
    (totalDiscounts, price) =>
      totalDiscounts + (price.bookingPrice.discountAmount ?? 0),
    0,
  );

  return [
    orderDate,
    booking.order_id,
    booking.platform?.name ?? placeholder,
    booking.order.external_reference ?? placeholder,
    ...visitor,
    booking.activity?.title ?? placeholder,
    bookingDate,
    bookingStartTime,
    t(booking.status),
    booking.total_unit,
    bookingPrices,
    totalBookingPrice,
    totalBookingDiscounts,
  ];
};

export const bookingsToFileDataTable = (
  bookings: TimeSlotBooking[],
  t: (key: string) => string,
  placeholder: string = "",
  forCsv?: boolean,
): FileTable => {
  const visitorHeader = generateVisitorFileDataHeader(t, !forCsv);

  const header = [
    t("orderDate"),
    t("orderId"),
    t("platform"),
    t("externalReference"),
    ...visitorHeader,
    t("activityName"),
    t("reservedDate"),
    t("reservedSlot"),
    t("bookingFilter.status"),
    t("totalUnits"),
    t("prices"),
    t("totalPrice"),
    t("discountAmount"),
  ];

  const rows = bookings.flatMap((timeSlotBooking) =>
    timeSlotBooking.bookings.map((v) =>
      bookingsToFileDataRow(v, t, placeholder, forCsv),
    ),
  );

  return { header, rows };
};

export const generateBookingTitle = (
  t: (key: string, options?: Record<string, string>) => string,
  from?: string,
  to?: string,
) => {
  return from && to
    ? t("myReservationsFromTo", { from, to })
    : t("bookingsTitle");
};

export const filterWithBookingsPlatforms = (
  booking: BookingType,
  platformsIds: string[],
): boolean => {
  if (platformsIds.length > 0) {
    return platformsIds.includes(booking.platform.id.toString());
  } else {
    return true;
  }
};
