import AlertMessage from "components/general/AlertMessage";
import Navbar from "components/navigation/Navbar";
import { Message } from "helpers/helpers";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./Emailing.css";
import MainTitle from "components/general/MainTitle";
import CreateTemplate from "components/emailing/CreateTemplate";
import AssignEmail from "components/emailing/AssignEmail/AssignEmail";
import { EmailTemplateTypeEnum, SectionEmailingEnum } from "helpers/constants";
import { useQuery } from "react-query";
import { getEmailTemplates } from "backend/api/emailing";
import ResponseError from "hooks/ResponseError";
import { useNavigate } from "react-router-dom";

export default function Emailing() {
  const { t: generalT } = useTranslation("general");
  const { t } = useTranslation("emailing");
  const navigate = useNavigate();
  const [alertMessage, setAlertMessage] = useState<Message>({
    type: "success",
    message: "",
  });

  const [allListTemplates, setAllListTemplate] = useState([]);
  const [listTemplatesReminder, setListTemplatesReminder] = useState([]);
  const [listTemplatesReview, setListTemplatesReview] = useState([]);
  const [listTemplatesCancellation, setListTemplatesCancellation] = useState(
    [],
  );
  const [listTemplatesConfirmation, setListTemplatesConfirmation] = useState(
    [],
  );
  const [listTemplatesLoyalty, setListTemplatesLoyalty] = useState([]);

  const currentSection: SectionEmailingEnum =
    window.location.pathname === "/emailing"
      ? SectionEmailingEnum.Emailing
      : SectionEmailingEnum.Assign;

  const NAVIGATIONS = [
    {
      title: t("createTemplate"),
      path: `/emailing`,
    },
    {
      title: t("assignTemplate"),
      path: `/assign-emailing`,
    },
  ];

  const { refetch: refetchTemplates } = useQuery(
    ["email-templates"],
    () => getEmailTemplates("all"),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setAllListTemplate(data);
        setListTemplatesReminder(
          data.filter((t: any) => t.type === EmailTemplateTypeEnum.REMINDER),
        );
        setListTemplatesReview(
          data.filter(
            (t: any) => t.type === EmailTemplateTypeEnum.REVIEW_REQUEST,
          ),
        );
        setListTemplatesConfirmation(
          data.filter(
            (t: any) => t.type === EmailTemplateTypeEnum.CONFIRMATION,
          ),
        );
        setListTemplatesCancellation(
          data.filter(
            (t: any) => t.type === EmailTemplateTypeEnum.CANCELLATION,
          ),
        );
        setListTemplatesLoyalty(
          data.filter((t: any) => t.type === EmailTemplateTypeEnum.LOYALTY),
        );
      },
      onError: (error) => ResponseError(error, navigate),
    },
  );

  return (
    <div className="page-emailing">
      <AlertMessage
        alertMessage={alertMessage.message}
        setAlertMessage={setAlertMessage}
        type={alertMessage.type}
        t={t}
      />

      <Navbar />
      <div className="container-emailing">
        <MainTitle navigations={NAVIGATIONS} title={generalT("emailing_sms")} />
        <div className="container-card">
          {currentSection === SectionEmailingEnum.Emailing && (
            <CreateTemplate
              t={t}
              setAlertMessage={setAlertMessage}
              refetchTemplates={refetchTemplates}
              allListTemplates={allListTemplates}
            />
          )}
          {currentSection === SectionEmailingEnum.Assign && (
            <AssignEmail
              t={t}
              setAlertMessage={setAlertMessage}
              listTemplatesReview={listTemplatesReview}
              listTemplatesReminder={listTemplatesReminder}
              listTemplatesConfirmation={listTemplatesConfirmation}
              listTemplatesCancellation={listTemplatesCancellation}
              listTemplatesLoyalty={listTemplatesLoyalty}
            />
          )}
        </div>
      </div>
    </div>
  );
}
