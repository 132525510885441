import { Platform } from "backend/api/platforms";
import { PlatformOptionType } from "./PlatformSelector.types";

export const generatePlatformOptions = (
  platforms: Platform[],
): PlatformOptionType[] => {
  return platforms.map((p) => ({
    value: p.id.toString(),
    label: p.name,
    avatar: p.logo?.[0],
  }));
};
