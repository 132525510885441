import { z } from "zod";
import {
  dateableWithTimeString,
  getAuthorizedConfig,
} from "../../helpers/helpers";
import axios from "axios";
import {
  ActivityPricesStatusEnum,
  BookingStatusEnum,
} from "../../helpers/constants";
import {
  ExtraInfo,
  GetBookingsForActivityOutput,
} from "components/agenda/types";
import { Platform } from "./platforms";

const visitorInfos = z.object({
  fullName: z.string(),
  email: z.string(),
  phoneNumber: z.string(),
});

const postBookingItem = z.object({
  numberOfUnit: z.number(),
  priceName: z.string(),
  from: dateableWithTimeString,
  to: dateableWithTimeString,
  status: z
    .enum([
      BookingStatusEnum.BOOKED,
      BookingStatusEnum.CANCELED,
      BookingStatusEnum.PENDING,
    ])
    .optional(),
});

// postBooking
export const postBookingInput = z.object({
  params: z.object({
    activityId: z.string(),
  }),
  body: z.object({
    visitorInfos: visitorInfos,
    bookings: z.array(postBookingItem),
    source: z.enum(["HOPLEISURE", "WIDGET"]),
  }),
});

export type PostBookingInput = z.infer<typeof postBookingInput>;

export async function postBooking(
  activityId: number,
  data: PostBookingInput["body"],
): Promise<any[]> {
  let config = getAuthorizedConfig("POST");
  config["url"] =
    `${process.env.REACT_APP_API_URL}/bookings/activity/${activityId}`;
  config["data"] = data;
  const response = await axios.request(config);
  const result = response.data;
  return result;
}

// putBooking
const putBookingItem = postBookingItem.extend({
  id: z.number().nullable(),
});
export const putBookingInput = z.object({
  params: z.object({
    activityId: z.string(),
  }),
  body: z.object({
    visitorInfos: visitorInfos,
    bookings: z.array(putBookingItem),
  }),
});

export type PutBookingInput = z.infer<typeof putBookingInput>;
export type PutBookingItem = z.infer<typeof putBookingItem>;

export async function putBooking(
  activityId: number,
  data: PutBookingInput["body"],
): Promise<any[]> {
  let config = getAuthorizedConfig("PUT");
  config["url"] =
    `${process.env.REACT_APP_API_URL}/bookings/activity/${activityId}`;
  config["data"] = data;
  const response = await axios.request(config);
  const result = response.data;
  return result;
}

// putBooking status
export const putBookingAndPaidStatusInput = z.object({
  body: z.object({
    bookingIds: z.array(z.number()),
    status: z.enum([
      BookingStatusEnum.BOOKED,
      BookingStatusEnum.CANCELED,
      BookingStatusEnum.DONE,
      BookingStatusEnum.NO_SHOW,
      BookingStatusEnum.PENDING,
    ]),
    paid: z.boolean().optional(),
  }),
});

export type PutBookingAndPaidStatusInput = z.infer<
  typeof putBookingAndPaidStatusInput
>;

export async function putBookingAndPaidStatus(
  bookingIds: number[],
  status: BookingStatusEnum,
  paid?: boolean,
): Promise<any[]> {
  let config = getAuthorizedConfig("PUT");
  config["url"] = `${process.env.REACT_APP_API_URL}/bookings/statusAndPaid`;
  config["data"] = { bookingIds, status, paid };
  const response = await axios.request(config);
  const result = response.data;
  return result;
}

export const postBookingUsersIdsInput = z.object({
  body: z.object({
    bookingId: z.number(),
    usersIds: z.number().array(),
  }),
});

export type PostBookingUsersIdsInput = z.infer<typeof postBookingUsersIdsInput>;

export async function postBookingUsersIds(
  bookingId: number,
  usersIds: number[],
): Promise<any[]> {
  let config = getAuthorizedConfig("POST");
  config["url"] =
    `${process.env.REACT_APP_API_URL}/bookings/update-booking-users-ids`;
  config["data"] = { bookingId, usersIds };
  const response = await axios.request(config);
  const result = response.data;
  return result;
}

export const postBookingsUsersIdsInput = z.object({
  body: z.object({
    bookingIds: z.number().array(),
    usersIds: z.number().array(),
  }),
});

export type PostBookingsUsersIdsInput = z.infer<
  typeof postBookingsUsersIdsInput
>;

export async function postBookingsUsersIds(
  bookingIds: number[],
  usersIds: number[],
): Promise<any[]> {
  let config = getAuthorizedConfig("POST");
  config["url"] =
    `${process.env.REACT_APP_API_URL}/bookings/update-bookings-users-ids`;
  config["data"] = { bookingIds, usersIds };
  const response = await axios.request(config);
  const result = response.data;
  return result;
}

// getBookings
export const getBookingsInputs = z.object({
  params: z.object({
    activityId: z.string(),
  }),
  query: z.object({
    from: dateableWithTimeString,
    to: dateableWithTimeString,
  }),
});

export type GetBookingsInputs = z.infer<typeof getBookingsInputs>;

export async function getBookings(
  activityId: number,
  from: string,
  to: string,
): Promise<GetBookingsForActivityOutput> {
  let config = getAuthorizedConfig("GET");
  config["url"] =
    `${process.env.REACT_APP_API_URL}/bookings/activity/${activityId}?from=${from}&to=${to}`;
  const response = await axios.request(config);
  const result = response.data;
  return result;
}

export const getCommentsInputs = z.object({
  params: z.object({
    activityId: z.string(),
  }),
  query: z.object({
    from: dateableWithTimeString,
    to: dateableWithTimeString,
  }),
});

export type GetCommentsInputs = z.infer<typeof getCommentsInputs>;

export async function getComments(
  activityId: number,
  from: string,
  to: string,
): Promise<any> {
  let config = getAuthorizedConfig("GET");
  config["url"] =
    `${process.env.REACT_APP_API_URL}/bookings/activity-comments/${activityId}?from=${from}&to=${to}`;
  const response = await axios.request(config);
  const result = response.data;
  return result;
}

export type Visitor = {
  id: number;
  created_at: string;
  full_name: string;
  email: string;
  phone_number: string;
  is_sign_in_with_google: boolean | null;
};

type Order = {
  id: number;
  created_at: string;
  external_reference: string | null;
};

type Category = {
  id: number;
  created_at: string;
  name: string;
};

type TimeSlot = {
  to: string;
  from: string;
};

type DailyHours = {
  slots: TimeSlot[];
};

export type ActivityType = {
  id: number;
  created_at: string;
  client_id: number;
  title: string;
  description: string;
  additionnal_informations: string | null;
  max_capacity: number;
  hours: DailyHours[];
  category_id: number;
  address: string;
  postal_code: string | null;
  activity_phone_number: string;
  activity_email: string;
  cancelation_policy: string;
  image_paths: string[];
  is_temporary: boolean;
  from: string | null;
  to: string | null;
  slot_duration: number;
  is_enabled: boolean;
  website: string;
  country: string;
  city: string;
  short_address: string | null;
  type: string;
  max_units_per_group: number;
  max_groups: number;
  is_deleted: boolean;
  timezone: string;
  reminder_template_id: number | null;
  review_req_template_id: number | null;
  type_hours: string;
  address_details: string;
  languages: string[];
};

type BookingPrice = {
  id: number;
  totalAmount: number;
  totalAmountWithDiscount: number;
  discountAmount: number;
};

export type PriceData = {
  id: number;
  name: string;
  price: number;
  status: ActivityPricesStatusEnum;
  number_of_unit: number;
  bookingPrice: BookingPrice;
};

export type BookingType = {
  id: number;
  activity: ActivityType;
  created_at: string;
  category: Category;
  visitor_id: number;
  activity_id: number;
  number_of_unit: number;
  status: BookingStatusEnum;
  from: string;
  to: string;
  price_id: number;
  booking_price_id: number;
  order_id: number;
  paid: boolean;
  platform_id: number;
  language: string | null;
  extra_infos: ExtraInfo[];
  lang: string | null;
  users_ids: number[];
  visitor: Visitor;
  order: Order;
  prices: PriceData[];
  assignedUsers: any[];
  platform: Platform;
  total_unit: number;
};

export type TimeSlotBooking = {
  timeSlot: string;
  bookings: BookingType[];
};

export async function getTimeslotBookings(params: {
  searchField?: string;
  from?: string;
  to?: string;
  orderBy?: string;
}): Promise<TimeSlotBooking[]> {
  let config = getAuthorizedConfig("GET");
  const filteredParams = Object.fromEntries(
    Object.entries(params).filter(([_, v]) => v),
  );
  config["params"] = filteredParams;
  config["url"] = `${process.env.REACT_APP_API_URL}/bookings`;
  const response = await axios.request(config);
  const result = response.data;
  return result;
}

export async function getBookingsToPdf(params: {
  searchField?: string;
  from?: string;
  to?: string;
  orderBy?: string;
}): Promise<Blob> {
  let config = getAuthorizedConfig("GET", "blob");
  const filteredParams = Object.fromEntries(
    Object.entries(params).filter(([_, v]) => v),
  );
  config["params"] = filteredParams;
  config["url"] =
    `${process.env.REACT_APP_API_URL}/bookings/export-to-pdf${params.toString() ? `?${params.toString()}` : ""}`;
  const response = await axios.request(config);
  const result = response.data;
  return result;
}

export async function getBookingsToCsv(params: {
  searchField?: string;
  from?: string;
  to?: string;
  orderBy?: string;
}): Promise<any> {
  let config = getAuthorizedConfig("GET", "blob");
  const filteredParams = Object.fromEntries(
    Object.entries(params).filter(([_, v]) => v),
  );
  config["params"] = filteredParams;
  config["url"] =
    `${process.env.REACT_APP_API_URL}/bookings/export-to-csv${params.toString() ? `?${params.toString()}` : ""}`;
  const response = await axios.request(config);
  const result = response.data;
  return result;
}

export const postCommentsInput = z.object({
  body: z.object({
    activityId: z.number(),
    comments: z.object({
      id: z.number().optional(),
      comment: z.string(),
      adcion: z.enum(["add", "delete", "edit"]),
    }),
  }),
});

export type PostCommentsInput = z.infer<typeof postCommentsInput>;

export async function postComments(
  activityId: number,
  from: string,
  to: string,
  comments: {
    id?: number;
    comment: string;
    action: "add" | "delete" | "edit";
  }[],
): Promise<any[]> {
  let config = getAuthorizedConfig("POST");
  config["url"] =
    `${process.env.REACT_APP_API_URL}/bookings/staff-assignment-comments`;
  config["data"] = { activityId, from, to, comments };
  const response = await axios.request(config);
  const result = response.data;
  return result;
}
