const profile: any = {
  en: {
    title: "My profile",
    titleTeam: "My Team",
    titleSetting: "Contact",
    customerInformations: "Customer informations",
    paymentMethodConnexion: "Connect a payment method to receive payments",
    alreadyConnectPaymentAccount:
      "You have already connected a payment account",
    dashboardReview: "View my dashboard",
    save: "Save",
    alerts: "ALERTS",
    receiveEmail: "Receive email",
    receiveSms: "Receive SMS",
    USER_UPDATED: "The user information has been successfully updated",
    USER_CREATED: "New user has been successfully created",
    ALREADY_EXISTING_USER:
      "An account already exists with this email address Please use a different email",
    USER_NOT_ADMINISTRATOR: "Action denied. User is not an administrator.",
    USER_NOT_SAME_CLIENT: "Action allowed only for users in your same client.",
    BAD_CREDENTIALS: "Bad credentials. Check your email or password.",
    NO_ADMINISTRATORS_LEFT:
      "You cannot delete or change the role of this user because they are the last administrator. Please assign a new administrator before proceeding",
    USER_DELETED:
      "The user has been successfully deleted and no longer has access to the system",
    NOT_FOUND: "User not found.",
    NO_ADMINISTRATORS_LEFT_TO_CHANGE_ROLE:
      "Impossible to change the role of this user. At least one administrator is required in the team",
    administrator: "Administrator",
    member: "Member",
    invite: "Invite",
    clientId: "Your clientId :",
    payzenRESTAPIKeys: "Payzen REST API Keys",
    errors: {
      companyName: "Company name is required",
      address: "Address is required",
      phone: "Phone number is required",
      email: "Email is required",
      stripeConnect:
        "An error occured, make sure you are in France and not using a VPN. If the problem persists, contact us.",
      PAYZEN_ACCOUNT_ID_NOT_VALID: "Please check Payzen account id is valid",
      PAYZEN_CONNECT_ERROR: "Fail connecting Payzen account",
    },
    success: {
      update: "Informations updated successfully",
      connectPayzenAccount: "Your Payzen account is saved successfully",
    },
    inputLabels: {
      companyName: "Company name",
      companyAddress: "Company address",
      siren: "N° SIREN",
      vat: "VAT number",
      vatRate: "VAT rate (%)",
      bankName: "Bank name",
      iban: "IBAN",
      bic: "BIC",
      email: "Contact email",
      phone: "Contact number",
      eachBooking: "For each booking / modification",
      eachCancellation: "For each cancellation",
      payzenAccountId: "Payzen account id",
      testPassword: "Test password",
      productionPassword: "Production Password",
      javascriptUrl: "JavaScript URL",
      publicJavascriptKey: "Public Javascript Key",
    },
    inputCard: {
      fullname: "Fullname",
      email: "Email",
      role: "Role",
      activities: "Supervised activities",
      toggle_email: "Email for each reservation",
      toogle_sms: "SMS for each reservation",
      update_btn: "Save",
      create_btn: "To register",

      editor_access: "Editor access",
      activities_no_access: "No access",
      no_access: "No access",

      editor_member_value:
        "Agenda, Reservation, Promotion, Activities, Website",
      activities_member_value: "My Profile, Dashboard",

      editor_invite_value: "Agenda, Reservation",
      no_access_invite_value:
        "Promotion, Activities, My Profile, Dashboard, Website",

      all_modules: "All modules",
    },
    "Expected string, received null": "Expected string, received null",
    "String must contain at least 10 character(s)":
      "String must contain at least 10 character(s)",
    confirmDeleteUser: "ARE YOU SURE YOU WANT TO DELETE THIS USER ?",
    noLongerAccessTheApp:
      "He will no longer have access to your account on https://app.hopleisure.com",
    yesDeleteUser: "YES DELETE USER",
    noGoBack: "NO GO BACK",
  },
  fr: {
    title: "Mon profil",
    titleTeam: "Mon Equipe",
    titleSetting: "Contact",
    customerInformations: "Informations Client",
    paymentMethodConnexion: "Connecter un moyen d'encaisser les paiements",
    alreadyConnectPaymentAccount:
      "Vous avez déjà connecté un compte de paiement",
    dashboardReview: "Consulter mon tableau de bord",
    save: "Enregistrer",
    alerts: "ALERTES",
    receiveEmail: "Recevoir un email",
    receiveSms: "Recevoir un SMS",
    USER_UPDATED:
      "Les informations de l'utilisateur ont été mises à jour avec succès",
    USER_CREATED: "Un nouvel utilisateur a été créé avec succès",
    ALREADY_EXISTING_USER:
      "Un compte existe déjà avec cette adresse email Veuillez utiliser une autre adresse email",
    USER_NOT_ADMINISTRATOR:
      "Action refusée. L'utilisateur n'est pas un administrateur.",
    USER_NOT_SAME_CLIENT:
      "Action autorisée uniquement pour les utilisateurs de votre même client.",
    BAD_CREDENTIALS:
      "Mauvaises informations d'identification. Vérifiez votre e-mail ou votre mot de passe.",
    NO_ADMINISTRATORS_LEFT:
      "Vous ne pouvez pas supprimer ou modifier le rôle de cet utilisateur car il s'agit du dernier administrateur. Veuillez désigner un nouvel administrateur avant de continuer",
    USER_DELETED:
      "L'utilisateur a été supprimé avec succès et n'a plus accès au système",
    NOT_FOUND: "Utilisateur non trouvé.",
    NO_ADMINISTRATORS_LEFT_TO_CHANGE_ROLE:
      "Impossible de changer le rôle de cet utilisateur. Au moins 1 administrateur est requis dans l'équipe",
    administrator: "Administrateur",
    member: "Membre",
    invite: "Invité",
    clientId: "Votre clientId :",
    payzenRESTAPIKeys: "Payzen : Clés API REST ",
    errors: {
      companyName: "Le nom de votre entreprise est requis",
      address: "L'adresse est requise",
      phone: "Le numéro de téléphone est requis",
      email: "L'email est requis",
      stripeConnect:
        "Une erreur est survenue, assurez vous d'être en France et de ne pas utiliser de VPN. Si le problème persiste, contactez nous.",
      PAYZEN_CONNECT_ERROR: "Échec de la connexion du compte Payzen",
      PAYZEN_ACCOUNT_ID_NOT_VALID:
        "Veuillez vérifier que l'identifiant du compte Payzen est valide",
    },
    success: {
      update: "Informations mises à jour avec succès",
      connectPayzenAccount: "Votre compte Payzen est enregistré avec succès!",
    },
    inputLabels: {
      companyName: "Nom de votre entreprise",
      companyAddress: "Adresse de votre entreprise",
      siren: "N° SIREN",
      vat: "N° TVA intracom.",
      vatRate: "Taux de TVA (%)",
      bankName: "Nom de la banque",
      iban: "IBAN",
      bic: "BIC",
      email: "Email de contact",
      phone: "Numéro de contact",
      eachBooking: "A chaque réservation / modification",
      eachCancellation: "A chaque annulation",
      payzenAccountId: "Identifiant de compte Payzen",
      testPassword: "Mot de passe de test",
      productionPassword: "Mot de passe de production",
      javascriptUrl: "URL JavaScript",
      publicJavascriptKey: "Clé Javascript publique",
    },
    inputCard: {
      fullname: "Nom et prénom",
      email: "Email",
      role: "Rôle",
      activities: "Activités encadrées",
      toggle_email: "Email à chaque réservation",
      toogle_sms: "SMS à chaque réservation",
      update_btn: "Sauvegarder",
      create_btn: "Enregister",

      editor_access: "Accès éditeur",
      activities_no_access: "Aucun accès",
      no_access: "Aucun accès",

      editor_member_value:
        "Agenda, Réservation, Promotion, Activités, Site Internet",
      activities_member_value: "Mon Profil,Tableau de Bord",

      editor_invite_value: "Agenda, Réservation",
      no_access_invite_value:
        "Promotion, Activités, Mon Profil, Tableau de Bord, Site Internet",

      all_modules: "Tous les modules",
    },
    "Expected string, received null": "Le champ est obligatoire",
    "String must contain at least 10 character(s)":
      "Le champ doit contenir au moins 10 caractères",
    confirmDeleteUser:
      "ÊTES-VOUS CERTAIN DE VOULOIR SUPPRIMER CET UTILISATEUR ?",
    noLongerAccessTheApp:
      "Il n’aura plus accès à votre compte sur https://app.hopleisure.com",
    yesDeleteUser: "OUI SUPPRIMER L’UTILISATEUR",
    noGoBack: "NON REVENIR EN ARRIÈRE",
  },
};

export default profile;
