import { styled } from "@mui/material";

export const BookingFormBody = styled("div")`
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

export const BookingFormSection = styled("div")`
  width: 100%;
  min-width: 450px;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const BookingButtonWrapper = styled("div")`
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  display: flex;
  gap: 1rem;
`;

export const BookingPrice = styled("span")(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 800,
}));
