import React, { createContext, useContext, useState, useEffect } from "react";
import { useQuery } from "react-query";
import { User } from "types/auth"; // Assurez-vous que le type User est bien défini
import { getClient } from "backend/api/clients";
import { useDispatch } from "react-redux";
import {
  removeUser,
  updateClientValue,
  updateUserValue,
} from "reducers/userReducer";
import { getUserMe } from "backend/api/profile";

interface UserContextType {
  user: User | undefined;
  setUser: React.Dispatch<React.SetStateAction<User | undefined>>;
  client: User | undefined;
  setClient: React.Dispatch<React.SetStateAction<User | undefined>>;
}

const defaultUserContext: UserContextType = {
  user: undefined,
  client: undefined,
  setUser: () => {},
  setClient: () => {},
};

const UserContext = createContext<UserContextType>(defaultUserContext);

export const useUser = (): UserContextType => {
  const context = useContext(UserContext);
  return context;
};

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const dispatch = useDispatch();
  const [user, setUser] = useState<User | undefined>(undefined);
  const [client, setClient] = useState<User | undefined>(undefined);

  const { refetch } = useQuery(["me"], getUserMe, {
    enabled: false,
    refetchOnWindowFocus: false,
  });

  const { data: userData } = useQuery(
    ["user", localStorage.getItem("userId")],
    getClient,
    {
      onSuccess: (data: User) => {
        setClient(data);
        dispatch(updateClientValue(data));
        refetch().then((res) => {
          setUser(res.data);
          dispatch(updateUserValue(res.data));
        });
      },
      onError: (error) => {
        setTimeout(() => {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("userId");
          localStorage.removeItem("clientId");
          setUser(undefined);

          window.location.reload();

          localStorage.removeItem("user");
          dispatch(removeUser());
        }, 300);
      },
      enabled:
        !!localStorage.getItem("userId") &&
        !!localStorage.getItem("accessToken"),
    },
  );

  useEffect(() => {
    if (userData) {
      setUser(userData);
    }
  }, [userData]);

  return (
    <UserContext.Provider value={{ user, setUser, client, setClient }}>
      {children}
    </UserContext.Provider>
  );
};
