const emailing = {
  en: {
    assignTemplate: "Assign template",
    createTemplate: "Create template",
    emailing: {
      title: "MY TEMPLATES",
      titleForm: "SEND SETTINGS",
      titleContent: "TEMPLATE TEXT",

      labelName: "Template Name",
      labelType: "Trigger event for sending",
      labelHours: "Setting the sending time",
      desc: "Number of hours to send before booking (for reminder) or after booking (for review request)",
      descReminder: "Set the time for your reminder in hours before the reserved slot.",
      descReview: "Set the time for your review request in hours after the reserved activity.",
      descLoyalty: "Set the frequency in days at which you want to contact your customer base to encourage them to return to your establishment (e.g., 90 days = 3 months).",
      withPromo: "Include a promo code",
      labelPromo: "Amount offered by the promo code (in €)",

      placeholderTextarea: "Please fill in the text below",
      inputFile: "Attachment",

      templateConfirmation: "Booking Confirmation",
      templateCancellation: "Booking Cancellation",
      templateLoyalty: "Loyalty - Customer Base",
      templateReminder: "Reminder prior to the activity",
      templateReview: "Review request after the activity",

      preview: "PREVIEW",
      buttonPrimary: "SAVE TEMPLATE",
    },

    assignEmail: {
      title: "CHOICE OF TEMPLATES BY ACTIVITY",
      checkboxEmail: "Send by email",
      checkboxSMS: "Send by SMS",

      activity: "Activity",
      confirmation: "Confirmation",
      cancellation: "Cancellation",
      reminder: "Prior reminder",
      review: "Review request",
      loyalty: "Loyalty",
      remove: "Remove",

      desc: "If no template is associated with the activity for the event in question, no message will be sent when the event occurs.",
      saveTemplate: "SAVE TEMPLATES FOR EACH ACTIVITY",
    },

    modalDelete: {
      title: "DO YOU WANT TO DELETE THIS TEMPLATE?",
      desc: "Please note that this action is irreversible. Activities for which this template is active will no longer have an active template and your customers will no longer receive alerts.",
      buttonSecondary: "CONFIRM DELETION",
      buttonPrimary: "BACK",
    },

    preview: {
      title: "AUTOMATIC EMAIL PREVIEW",

      hereIsTheContent:
        "Here is the content of the message that your clients will receive for the selected event:",
      emailSmsRappel: "Reminder Email/SMS.",

      theSubject:
        "The subject, greeting and closing phrases, as well as reservation details (schedule, contact information, selected activity, chosen rates...) are automatically generated by HoPleisure.",
      youCanInsert:
        "You can insert a personalized message in the email body, but be careful not to include specific reservation details as this template will be reused for all messages!",
      thisPersonalized:
        "This personalized message appears in green in the preview.",

      confirmReservation: "Booking Confirmation",
      cancelReservation: "Booking Cancellation",
      loyalty: "To thank you for your loyalty - Activity A",
      rappelReservation: "BOOKING REMINDER: Activity A",
      demandeReservation: "Activity A - Support us with a positive review !",
      hallo: "Hello ",
      weAreDelighted: "We are delighted to have welcomed you recently and we hope you had a great time with us.",
      thankYouLoyalty: "To thank you for your loyalty, here's a discount code valid on our activities: ",
      yourBenefit: "It will allow you to benefit from {{amount}} euros offered on your next visit!",
      establishmentContact: "Establishment contact details :",
      establishmentEmail: "Email:",
      establishmentValueEmail: "escapegame.domicile@gmail.com",
      establishmentTelephone: "Phone:",
      establishmentValueTelephone: "06 52 52 52 52",

      yourContact: "Your contact details:",
      yourEmail: "Email:",
      yourValueEmail: "thomaslevacancier@gmail.com",
      yourTelephone: "Phone:",
      yourValueTelephone: "06 18 18 18 18",

      haveANiceDay: "Have a nice day and see you soon.",

      bookingDetail: "Here are the booking details :",

      activity: "Activities :",
      valueActivity: "murder party 2",

      date: "Date and Time:",
      valueDate: "2024-06-11 09:00 to 2024-06-11 11:00",

      address: "Address:",
      valueAddress: "rue de la Vie, Montgeron, France",

      number: "Number of seats :",
      valueNumber: "1",

      total: "Total Price :",
      valueTotal: "12.00 €",

      thankYouBookings: "Thank you for booking with {{companyName}}.",
      reservationExperience: "Your booking for the experience {{title}}",
      canceledBookings: "on the date {{date}} has unfortunately been canceled.",

      reservationNumber: "Booking Number:",
      valueReservationNumber: "101",

      receptionPhone: "Reception Phone (Day of Event):",
      valueReceptionPhone: "06 17 17 17 17",

      desc: "This experience is offered for booking by HoPleisure SAS, registered under the SIREN number 952 553 139, acting on behalf of and for the account of the company ${client_company_name}, registered under the SIREN number ${client_siren}, in accordance with the mandate entrusted to it.",
      qrTitle: "Do not lose this QR code, it will be requested by the manager.",
      attachment: "Attachment:",

      buttonSecondary: "BACK TO EDITION",
      buttonPrimary: "SAVE THE TEMPLATE",
    },

    EMAIL_TEMPLATE_UPDATED: "Email template updated",
    EMAIL_TEMPLATE_CREATED: "Email template created",
    EMAIL_TEMPLATE_DELETED: "Email template deleted",

    ACTIVITY_EMAIL_TEMPLATE_UPDATED: "Activity email template updated",
  },
  fr: {
    assignTemplate: "Affecter des templates",
    createTemplate: "Créer des templates",
    emailing: {
      title: "MES TEMPLATES",
      titleForm: "PARAMÈTRES DE L'ENVOI",
      titleContent: "TEXTE DU TEMPLATE",

      labelName: "Nom du template",
      labelType: "Évènement déclencheur de l’envoi",
      labelHours: "Paramétrage de l’heure d’envoi",
      desc: "Nombre d’heures pour l’envoi avant la réservation (pour le rappel) ou après la réservation (pour la demande d’avis)",
      descReminder: "Programmez l’heure de votre rappel, en nombre d’heures avant le créneau réservé.",
      descReview: "Programmez l’heure de votre demande d’avis, en nombre d’heures après l’actiivté reservée.",
      descLoyalty: "Programmez la fréquence en jours à laquelle vous souhaitez recontacter votre base client pour leur proposer de revenir dans votre établissement (90 jours = 3 mois par exemple).",
      withPromo: "Inclure un code promo",
      labelPromo: "Montant offert par le code promo (en €)",

      placeholderTextarea: "Veuillez remplir le texte ci-dessous",
      inputFile: "Pièce-jointe",

      templateConfirmation: "Confirmation de réservation",
      templateCancellation: "Annulation de réservation",
      templateLoyalty: "Fidélité - Base client",
      templateReminder: "Rappel préalable à l’expérience",
      templateReview: "⁠Demande d’avis postérieure à l’expérience",

      preview: "APERÇU",
      buttonPrimary: "ENREGISTRER LE TEMPLATE",
    },

    assignEmail: {
      title: "CHOIX DES TEMPLATES PAR ACTIVITÉ",
      checkboxEmail: "Envoi par email",
      checkboxSMS: "Envoi par SMS",

      activity: "Activité",
      confirmation: "Confirmation",
      cancellation: "Annulation",
      reminder: "Rappel préalable",
      review: "Demande d’avis",
      loyalty: "Fidélité",
      remove: "Retirer",

      desc: "Si aucun template n'est associé à l'activité pour l'événement concerné, aucun message ne sera envoyé lors de la survenance de l'événement.",
      saveTemplate: "SAUVEGARDER LES TEMPLATES POUR CHAQUE ACTIVITÉ",
    },

    modalDelete: {
      title: "SOUHAITEZ-VOUS SUPPRIMER CE TEMPLATE ?",
      desc: "Attention, cette action est irréversible. Les activités pour lesquels ce template est actif, n’auront plus de template actif et vos clients en recevront plus d’alertes.",
      buttonSecondary: "CONFIRMER LA SUPPRESSION",
      buttonPrimary: "RETOUR",
    },

    preview: {
      title: "PREVISUALISATION DE L’EMAIL AUTOMATIQUE",

      hereIsTheContent:
        "Voici le contenu du message que recevrons vos clients pour l’évènement sélectionné :",
      emailSmsRappel: "Email/SMS de rappel.",

      theSubject:
        "L’objet, les formules de politesse initiales et de clotûre ainsi que les détails de la réservation (horaires, coordonnées, activité choisi, tarifs choisis...) sont automatiquement générés par HoPleisure.",
      youCanInsert:
        "Vous pouvez insérer un passage personnalisé grâce au corps de l’email mais veillez à ne pas inclure d’informations spécifiques à une réservation car ce template sera réutilisé lors de tous les envois !",
      thisPersonalized:
        "Ce message personnalisé apparait en vert dans la prévisualisation.",

      confirmReservation: "Confirmation de réservation",
      cancelReservation: "Réservation annulée",
      loyalty: "Pour vous remercier de votre fidélité - Activity A",
      rappelReservation: "Rappel de réservation : Activité A",
      demandeReservation: "Activity A - Soutenez-nous avec un avis !",
      hallo: "Bonjour ",
      weAreDelighted: "Nous sommes ravis de vous avoir accueilli  récemment et nous espérons que vous avez passé un moment exceptionnel en notre compagnie.",
      thankYouLoyalty: "Pour vous remercier de votre fidélité, voici, un code de réduction valable sur nos activité :",
      yourBenefit: "Il vous permettra de bénéficier de {{amount}} euros offerts lors de votre prochaine visite !",

      establishmentContact: "Coordonnées de l'établissement :",
      establishmentEmail: "Email :",
      establishmentValueEmail: "escapegame.domicile@gmail.com",
      establishmentTelephone: "Téléphone :",
      establishmentValueTelephone: "06 52 52 52 52",

      yourContact: "Vos coordonnées :",
      yourEmail: "Email :",
      yourValueEmail: "thomaslevacancier@gmail.com",
      yourTelephone: "Téléphone :",
      yourValueTelephone: "06 18 18 18 18",

      haveANiceDay: "Bonne journée et à très vite.",

      bookingDetail: "Voici les détails de la réservation :",

      activity: "Activités :",
      valueActivity: "murder party 2",

      date: "Date et heure :",
      valueDate: "2024-06-11 09:00 to 2024-06-11 11:00",

      address: "Adresse :",
      valueAddress: "rue de la Vie, Montgeron, France",

      number: "Nombre de places :",
      valueNumber: "1",

      total: "Prix total :",
      valueTotal: "12.00 €",

      thankYouBookings: "Merci d'avoir réservé chez {{companyName}}.",
      reservationExperience: "Votre réservation pour l’expérience {{title}}",
      canceledBookings: "sur la date du insert {{date}} a malheureusement due être annulée.",

      reservationNumber: "Numéro de réservation :",
      valueReservationNumber: "101",

      receptionPhone: "Téléphone de l’accueil (Jour J) :",
      valueReceptionPhone: "06 17 17 17 17",

      desc: "Cette expérience vous est proposée à la réservation par HoPlaisure SAS, enregistrée sous le numéro SIREN 952 553 139, agissant au nom et pour le compte de la société ${client_company_name}, enregistrée sous le numéro SIREN ${client_siren} conformément au mandat qui lui a été confié.",
      qrTitle: "Ne perdez pas ce QR code, il vous sera demandé par le gérant.",
      attachment: "Pièce-jointe :",

      buttonSecondary: "RETOUR A L’EDITION",
      buttonPrimary: "ENREGISTRER LE TEMPLATE",
    },

    EMAIL_TEMPLATE_UPDATED: "Modèle d'e-mail mis à jour",
    EMAIL_TEMPLATE_CREATED: "Modèle d'e-mail créé",
    EMAIL_TEMPLATE_DELETED: "Modèle d'e-mail supprimé",

    ACTIVITY_EMAIL_TEMPLATE_UPDATED: "Modèle d'e-mail d'activité mis à jour",
  },
};

export default emailing;