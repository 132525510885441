import { Button } from "@mui/material";
import { styled } from "@mui/system";

export const ExportButtonStyle = styled(Button)(({ theme }) => ({
  width: "100%",
  backgroundColor: "rgba(132, 220, 198, 0.25)",
  padding: "6px 8px",
  borderRadius: 8,

  "&:hover": {
    backgroundColor: "rgba(132, 220, 198, 0.5)",
  },

  "&:disabled": {
    backgroundColor: theme.palette.action.disabled,
  },
}));

export const PageStyle = styled("div")({
  display: "flex",
  height: "100%",
  position: "relative",
});

export const ContainerStyle = styled("div")({
  position: "relative",
  margin: "30px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
  flex: 1,
  gap: "20px",
});
