import { DATE_FORMATS } from "constants/dateConstants";
import moment, { Moment } from "moment";

export function parseDate(dateString?: string | null): Moment | null {
  return dateString ? moment(dateString) : null;
}

export function getCurrentDate(): Moment {
  return moment();
}

export function formatDate(
  date: Moment,
  format: string = DATE_FORMATS.DATE_ONLY,
): string {
  return date.format(format);
}

export function getStartOfDay(date: Moment): Moment {
  return date.startOf("day");
}

export function getEndOfDay(date: Moment): Moment {
  return date.endOf("day");
}
