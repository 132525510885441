import React from "react";
import { Control, FieldErrors, UseFormSetValue } from "react-hook-form";
import { WidgetType } from "../WidgetTab.types";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { InputText, UncontrolledSwitch } from "components/formInputs/Inputs";
import { TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import "./ExtraInfosFields.css";
import { HtmlTooltip } from "components/activities/formCards/hoursForm/HoursForm";
import InfoIcon from "@mui/icons-material/Info";

interface ExtraInfoFieldsProps {
  control: Control<WidgetType>;
  errors: FieldErrors<WidgetType>;
  setValue: UseFormSetValue<WidgetType>;
  extraInfos: WidgetType["extraInfos"];
  onRemove: (index: number) => void;
  t: (key: string) => string;
}

export const ExtraInfoFields: React.FC<ExtraInfoFieldsProps> = ({
  control,
  errors,
  setValue,
  extraInfos,
  onRemove,
  t,
}) => {
  const handleToggleAnswerRequired = (index: number, currentValue: boolean) => {
    setValue(`extraInfos.${index}.answerRequired`, !currentValue);
  };

  const handleToggleAnswerOptions = (
    index: number,
    currentOptions: string[],
  ) => {
    const newValue = currentOptions?.length ? [] : [" "];
    setValue(`extraInfos.${index}.answerOptions`, newValue);
  };

  const handleAnswerOptionsChange = (
    index: number,
    value: string,
    onChange: (value: string[]) => void,
  ) => {
    if (value === "") {
      onChange([]);
    } else {
      onChange(value.split(",").map((v) => v.trim()));
    }
  };

  return (
    <>
      {extraInfos?.map((field, index) => (
        <div key={index}>
          <div className="horizontal-info-group">
            <InputText
              control={control}
              name={`extraInfos[${index}].question`}
              id={`extraInfos[${index}].question`}
              label={`${t("extraInfoInputLabel")} ${index + 1}`}
              t={t}
              error={errors.extraInfos?.[index]?.message}
            />
            <RemoveCircleOutlineIcon
              style={{ color: "red", cursor: "pointer" }}
              onClick={() => onRemove(index)}
            />
            <div>
              <div className="extra-info-config-switch-container">
                <UncontrolledSwitch
                  key={index}
                  isActive={field.answerRequired}
                  onChange={() =>
                    handleToggleAnswerRequired(index, field.answerRequired)
                  }
                  t={t}
                />
                <span className="extra-info-config-switch-label">
                  {t("answerRequired")}
                </span>
              </div>
              <div className="extra-info-config-switch-container">
                <UncontrolledSwitch
                  key={index + "1"}
                  isActive={Boolean(field.answerOptions?.toString())}
                  onChange={() =>
                    handleToggleAnswerOptions(index, field.answerOptions || [])
                  }
                  t={t}
                />
                <span className="extra-info-config-switch-label">
                  {t("answersList")}
                </span>
              </div>
            </div>
          </div>
          {Boolean(field.answerOptions?.toString()) && (
            <div className="answers-list-container">
              <span className="answers-list-label">{t("answers")}:</span>
              <Controller
                name={`extraInfos.${index}.answerOptions`}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id={`extraInfos[${index}].answerOptions`}
                    fullWidth
                    error={Boolean(errors?.extraInfos?.[index]?.answerOptions)}
                    helperText={
                      errors?.extraInfos?.[index]?.answerOptions?.message || ""
                    }
                    value={
                      Array.isArray(field.value)
                        ? field.value.join(", ")
                        : field.value || ""
                    }
                    onChange={(event) =>
                      handleAnswerOptionsChange(
                        index,
                        event.target.value,
                        field.onChange,
                      )
                    }
                  />
                )}
              />
              <HtmlTooltip title={t("ansersListTooltipText")}>
                <InfoIcon color="primary" />
              </HtmlTooltip>
            </div>
          )}
        </div>
      ))}
    </>
  );
};
