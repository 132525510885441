import { Typography, Box, Select, SelectChangeEvent } from "@mui/material";
import { EmailTemplateTypeEnum } from "helpers/constants";
import { useMemo } from "react";
import ExQR from "images/exQR.png";
import ENFlag from "images/eng_flag.png";
import FRFlag from "images/fr_flag.png";
import {
  GreenText,
  HeaderBox,
  ImageFlag,
  ImageQR,
  InfoSection,
  StyledStrong,
  StyleMenuItem,
  TypoDescription,
  TypoTitle2,
  TypoTitle,
  WrapperImageQr,
  TypoAttachment,
} from "./EmailPreview.style";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { EmailPreviewProps, Language } from "./EmailPreview.type";

const TEMPLATE_TITLE_MAP = {
  [EmailTemplateTypeEnum.CONFIRMATION]: "preview.confirmReservation",
  [EmailTemplateTypeEnum.CANCELLATION]: "preview.cancelReservation",
  [EmailTemplateTypeEnum.REMINDER]: "preview.rappelReservation",
  [EmailTemplateTypeEnum.REVIEW_REQUEST]: "preview.demandeReservation",
  [EmailTemplateTypeEnum.LOYALTY]: "preview.loyalty",
};

export default function EmailPreview({
  t,
  type,
  amount,
  content,
  filename,
  language,
  setLanguage,
  discountCode,
}: EmailPreviewProps) {
  const { user } = useSelector((state: RootState) => state.user);

  const title = useMemo(() => {
    return TEMPLATE_TITLE_MAP[type as keyof typeof TEMPLATE_TITLE_MAP] || "";
  }, [type]);

  const handleLanguageChange = (event: SelectChangeEvent) => {
    setLanguage(event.target.value as Language);
  };

  const renderTemplateSpecificContent = () => {
    switch (type) {
      case EmailTemplateTypeEnum.CONFIRMATION:
        return (
          <TypoTitle variant="body1">
            {t("preview.thankYouBookings", {
              companyName: "Company A",
              lng: language,
            })}
          </TypoTitle>
        );

      case EmailTemplateTypeEnum.CANCELLATION:
        return (
          <TypoTitle variant="body1">
            {t("preview.reservationExperience", {
              title: "Activity A",
              lng: language,
            })}{" "}
            {t("preview.canceledBookings", {
              date: "2024-06-11 09:00",
              lng: language,
            })}
          </TypoTitle>
        );

      case EmailTemplateTypeEnum.LOYALTY:
        if (!amount) return null;
        return (
          <>
            <TypoTitle variant="body1">
              {t("preview.weAreDelighted", { lng: language })}
            </TypoTitle>
            <TypoTitle variant="body1">
              {t("preview.thankYouLoyalty", { lng: language })}{" "}
              <strong>{discountCode}</strong>
            </TypoTitle>
            <TypoTitle variant="body1">
              {t("preview.yourBenefit", { amount, lng: language })}
            </TypoTitle>
          </>
        );

      default:
        return null;
    }
  };

  const renderBookingDetails = () => {
    if (type === EmailTemplateTypeEnum.LOYALTY) return null;

    return (
      <>
        <Typography variant="body1" sx={{ mb: 1 }}>
          {t("preview.bookingDetail", { lng: language })}
        </Typography>

        <Box sx={{ ml: 3, p: 0 }}>
          {(type === EmailTemplateTypeEnum.CANCELLATION ||
            type === EmailTemplateTypeEnum.CONFIRMATION) && (
            <Typography sx={{ mb: 1 }}>
              &#8226;
              <StyledStrong>
                {t("preview.reservationNumber", { lng: language })}
              </StyledStrong>{" "}
              {t("preview.valueReservationNumber", { lng: language })}
            </Typography>
          )}
          <Typography sx={{ mb: 1 }}>
            &#8226;
            <StyledStrong>
              {t("preview.activity", { lng: language })}
            </StyledStrong>{" "}
            {t("preview.valueActivity", { lng: language })}
          </Typography>
          <Typography sx={{ mb: 1 }}>
            &#8226;
            <StyledStrong>
              {t("preview.date", { lng: language })}
            </StyledStrong>{" "}
            {t("preview.valueDate", { lng: language })}
          </Typography>
          <Typography sx={{ mb: 1 }}>
            &#8226;
            <StyledStrong>
              {t("preview.address", { lng: language })}
            </StyledStrong>{" "}
            {t("preview.valueAddress", { lng: language })}
          </Typography>
          <Typography sx={{ mb: 1 }}>
            &#8226;
            <StyledStrong>
              {t("preview.number", { lng: language })}
            </StyledStrong>{" "}
            {t("preview.valueNumber", { lng: language })}
          </Typography>
          <Typography sx={{ mb: 1 }}>
            &#8226;
            <StyledStrong>
              {t("preview.total", { lng: language })}
            </StyledStrong>{" "}
            {t("preview.valueTotal", { lng: language })}
          </Typography>
          {(type === EmailTemplateTypeEnum.CANCELLATION ||
            type === EmailTemplateTypeEnum.CONFIRMATION) && (
            <Typography sx={{ mb: 1 }}>
              &#8226;
              <StyledStrong>
                {t("preview.receptionPhone", { lng: language })}
              </StyledStrong>{" "}
              {t("preview.valueReceptionPhone", { lng: language })}
            </Typography>
          )}
        </Box>
      </>
    );
  };

  const renderQRCode = () => {
    if (type !== EmailTemplateTypeEnum.CONFIRMATION) return null;

    return (
      <>
        <Typography variant="body1" sx={{ mb: 1, textAlign: "center" }}>
          <strong>{t("preview.qrTitle", { lng: language })}</strong>
        </Typography>
        <WrapperImageQr>
          <ImageQR src={ExQR} alt="Example QR" />
        </WrapperImageQr>
      </>
    );
  };

  const renderContactInfo = () => (
    <InfoSection sx={{ backgroundColor: "#f0f8ff" }}>
      <TypoTitle2>
        {t("preview.establishmentContact", { lng: language })}
      </TypoTitle2>
      <Typography sx={{ mb: 1 }}>
        {t("preview.yourEmail", { lng: language })}{" "}
        {t("preview.establishmentValueEmail", { lng: language })}
      </Typography>
      <Typography sx={{ mb: 2 }}>
        {t("preview.establishmentTelephone", { lng: language })}{" "}
        {t("preview.establishmentValueTelephone", { lng: language })}
      </Typography>
      <TypoTitle2>{t("preview.yourContact", { lng: language })}</TypoTitle2>
      <Typography sx={{ mb: 1 }}>
        {t("preview.yourEmail", { lng: language })}{" "}
        {t("preview.yourValueEmail", { lng: language })}
      </Typography>
      <Typography sx={{ mb: 1 }}>
        {t("preview.yourTelephone", { lng: language })}{" "}
        {t("preview.yourValueTelephone", { lng: language })}
      </Typography>
    </InfoSection>
  );

  return (
    <div>
      <h2 className="title">{t("emailing.preview")}</h2>
      <Select
        hiddenLabel
        value={language}
        onChange={handleLanguageChange}
        style={{ marginBottom: 10 }}
      >
        <StyleMenuItem value="fr">
          <ImageFlag src={FRFlag} alt="FR" /> Français (FR)
        </StyleMenuItem>
        <StyleMenuItem value="en">
          <ImageFlag src={ENFlag} alt="EN" /> English (EN)
        </StyleMenuItem>
      </Select>

      {!!type && (
        <>
          <div>
            <HeaderBox>
              <Typography variant="h6" style={{ textAlign: "center" }}>
                {t(title, { lng: language })}
              </Typography>
            </HeaderBox>

            <TypoTitle variant="body1">
              {t("preview.hallo", { lng: language })}, {user.fullName}
            </TypoTitle>

            {renderTemplateSpecificContent()}

            {content && <GreenText variant="body1">{content}</GreenText>}

            {renderBookingDetails()}
            {renderQRCode()}
            {renderContactInfo()}

            <TypoDescription sx={{ mt: 3 }}>
              {t("preview.haveANiceDay", { lng: language })}
            </TypoDescription>
            <TypoDescription sx={{ mt: 1 }}>
              {t("preview.desc")}
            </TypoDescription>
          </div>

          {filename && (
            <TypoAttachment>
              {t("preview.attachment", { lng: language })} {filename}
            </TypoAttachment>
          )}
        </>
      )}
    </div>
  );
}
