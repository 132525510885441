import { jsPDFOptions } from "jspdf";
import { UserOptions } from "jspdf-autotable";

export const FILE_EXTENSIONS = {
  PDF: "pdf",
  CSV: "csv",
  TXT: "txt",
};

export const MIME_TYPES = {
  PDF: "application/pdf",
  CSV: "text/csv",
  TXT: "text/plain",
};

const PDF_FILE_OPTIONS: jsPDFOptions = {
  orientation: "landscape",
  unit: "mm",
  format: "a4",
};
const PDF_TABLE_OPTIONS: UserOptions = {
  startY: 20,
  styles: {
    fontSize: 9,
  },
  headStyles: {
    fillColor: "#054643",
    textColor: "#ffffff",
    fontStyle: "bold",
    halign: "center",
    fontSize: 9,
  },
  alternateRowStyles: {
    fillColor: "#E0F9F3",
  },
};

export const FILE_CONFIG = {
  PDF_FILE: PDF_FILE_OPTIONS,
  PDF_TABLE: PDF_TABLE_OPTIONS,
  PDF_TABLE_TITLE: {
    x: 14,
    y: 10,
  },
};
