import React from "react";
import { Control, useFieldArray } from "react-hook-form";
import AlertBodyItem from "./AlertBodyItem";
import { ActivityAlert } from "backend/api/clients";

interface AlertBodyProps {
  control: Control<{ activities: ActivityAlert[] }>;
}

export default function AlertBody({ control }: AlertBodyProps) {
  const { fields } = useFieldArray({ control, name: "activities" });

  return (
    <tbody>
      {fields.map((field, index) => (
        <AlertBodyItem key={field.id} index={index} control={control} />
      ))}
    </tbody>
  );
}
