import { TextField } from "@mui/material";
import { getUsers } from "backend/api/profile";
import BookingsFilter from "components/bookings/BookingsFilters/BookingsFilters";
import TimeSlotBox from "components/bookings/TimeSlotBox";
import { FILE_EXTENSIONS, MIME_TYPES } from "constants/fileConstants";
import { BookingStatusEnum } from "helpers/constants";
import {
  createFileName,
  downloadFile,
  fileTableToCSV,
  fileTableToPDF,
} from "helpers/files.helpers";
import { useDebounce } from "hooks/useDebounce";
import CSVIcon from "images/csv.png";
import PDFIcon from "images/pdf.png";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { User } from "types/auth";
import {
  getTimeslotBookings,
  TimeSlotBooking,
} from "../../backend/api/bookings";
import AlertMessage from "../../components/general/AlertMessage";
import MainTitle from "../../components/general/MainTitle";
import Navbar from "../../components/navigation/Navbar";
import { isArrayEmpty, Message } from "../../helpers/helpers";
import { BOOKINGS_COLUMN_STYLES } from "./Bookings.constants";
import {
  bookingsToFileDataTable,
  filterWithBookingsPlatforms,
  generateBookingTitle,
} from "./Bookings.helpers";
import { ContainerStyle, ExportButtonStyle, PageStyle } from "./Bookings.style";

export default function Bookings() {
  const { t } = useTranslation("bookings");
  const [selectedActivities, setSelectedActivities] = useState<string[]>([]);
  const [selectedPlatformsIds, setSelectedPlatformsIds] = useState<string[]>(
    [],
  );
  const [selectedStatus, setSelectedStatus] = useState<string[]>([]);
  const [bookings, setBookings] = useState<TimeSlotBooking[]>([]);
  const [filteredBookings, setFilteredBookings] = useState<TimeSlotBooking[]>(
    [],
  );
  const [selectedSortFilter, setSelectedSortFilter] = useState("");
  const [search, setSearch] = useState("");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [users, setUsers] = useState<User[]>([]);
  const [selectedMembers, setSelectedMembers] = useState<string[]>([]);
  const debouncedSearch = useDebounce(search, 2000);
  const [alertMessage, setAlertMessage] = useState<Message>({
    type: "success",
    message: "",
  });

  useQuery({
    queryKey: [
      "getTimeslotBookings",
      debouncedSearch,
      from,
      to,
      selectedSortFilter,
    ],
    queryFn: () =>
      getTimeslotBookings({
        searchField: search,
        from,
        to,
        orderBy: selectedSortFilter,
      }),
    refetchOnWindowFocus: false,
    staleTime: 5000,
    onSuccess(data) {
      setBookings(data);
    },
  });

  const exportPdf = () => {
    const table = bookingsToFileDataTable(filteredBookings, t, "-");
    const fileTitle = generateBookingTitle(t, from, to);

    const pdfData = fileTableToPDF(table, fileTitle, BOOKINGS_COLUMN_STYLES);
    const fileName = createFileName(fileTitle, FILE_EXTENSIONS.PDF);
    downloadFile(pdfData, fileName, MIME_TYPES.PDF);
  };

  const exportCsv = () => {
    const table = bookingsToFileDataTable(filteredBookings, t, "", true);
    const pdfData = fileTableToCSV(table);

    const fileTitle = generateBookingTitle(t, from, to);
    const fileName = createFileName(fileTitle, FILE_EXTENSIONS.CSV);

    downloadFile(pdfData, fileName, MIME_TYPES.CSV);
  };

  useQuery("users", getUsers, {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      const newData = data.filter((item: any) => item.status !== "DELETED");
      setUsers(newData);
    },
  });

  useEffect(() => {
    const filteredBookingsTmp = bookings
      ?.map((timeSlot) => ({
        ...timeSlot,
        bookings: timeSlot.bookings
          .filter((booking) => {
            if (selectedActivities.length > 0) {
              return selectedActivities.includes(booking.activity.title);
            } else {
              return true;
            }
          })
          .filter((booking) => {
            return booking.status !== BookingStatusEnum.TO_PAY;
          })
          .filter((booking) => {
            if (isArrayEmpty(selectedMembers)) {
              return true;
            } else {
              return (
                booking.assignedUsers &&
                booking.assignedUsers.some((user: any) =>
                  selectedMembers.includes(user.id.toString()),
                )
              );
            }
          })
          .filter((booking) => {
            if (isArrayEmpty(selectedStatus)) return true;
            if (selectedStatus.includes(BookingStatusEnum.CANCELED)) {
              return [
                BookingStatusEnum.DELETED_BY_CLIENT,
                ...selectedStatus,
              ].includes(booking.status);
            }
            return selectedStatus.includes(booking.status);
          })
          .filter((booking) =>
            filterWithBookingsPlatforms(booking, selectedPlatformsIds),
          ),
      }))
      .filter((timeSlot) => timeSlot.bookings.length > 0);
    setFilteredBookings(filteredBookingsTmp);
  }, [selectedActivities, bookings, selectedMembers, selectedStatus]);

  return (
    <PageStyle>
      <Navbar />
      <ContainerStyle>
        <AlertMessage
          alertMessage={alertMessage.message}
          setAlertMessage={setAlertMessage}
          type={alertMessage.type}
          t={t}
        />
        <MainTitle title={t("bookingsTitle")} />
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 71,
          }}
        >
          <TextField
            size="medium"
            sx={{ textAlign: "center" }}
            fullWidth
            hiddenLabel
            id="fullWidth"
            placeholder={t("bookingFilter.search")}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 10,
              width: "50%",
            }}
          >
            <ExportButtonStyle
              onClick={() => exportPdf()}
              disabled={isArrayEmpty(filteredBookings)}
            >
              <img alt="pdf" src={PDFIcon} width={25} height={25} />
              <p style={{ margin: 0 }}>{t("exportPdf")}</p>
            </ExportButtonStyle>
            <ExportButtonStyle
              onClick={() => exportCsv()}
              disabled={isArrayEmpty(filteredBookings)}
            >
              <img alt="csv" src={CSVIcon} width={25} height={25} />
              <p style={{ margin: 0 }}>{t("exportCsv")}</p>
            </ExportButtonStyle>
          </div>
        </div>
        <BookingsFilter
          users={users}
          bookings={bookings}
          selectedActivities={selectedActivities}
          setSelectedActivities={setSelectedActivities}
          selectedPlatformsIds={selectedPlatformsIds}
          setSelectedPlatformsIds={setSelectedPlatformsIds}
          setSelectedSortFilter={setSelectedSortFilter}
          setFrom={setFrom}
          setTo={setTo}
          from={from}
          to={to}
          t={t}
          selectedMembers={selectedMembers}
          setSelectedMembers={setSelectedMembers}
          selectedStatus={selectedStatus}
          setSelectedStatus={setSelectedStatus}
        />
        {!isArrayEmpty(filteredBookings) &&
          filteredBookings.map((booking, index) => (
            <TimeSlotBox key={index} users={users} booking={booking} t={t} />
          ))}
      </ContainerStyle>
    </PageStyle>
  );
}
