import {
  Button,
  IconButton,
  styled,
  TableCell,
  TableContainer,
  TableHead,
} from "@mui/material";

export const StyledIconButton = styled(IconButton)`
  position: absolute !important;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

export const StyledTableContainer = styled(TableContainer)`
  margin-top: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
`;

export const StyledTableHead = styled(TableHead)`
  background-color: #f9f9f9;
`;

export const StyledTableCell = styled(TableCell)`
  text-align: center;
`;

export const StyledButton = styled(Button)`
  border-radius: 20px;
  padding: 5px 25px;
`;
