import React, { useEffect, useMemo, useState } from "react";
import {
  IconButton,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Modal,
  Box,
  Switch,
  FormLabel,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import Cancel from "@mui/icons-material/Cancel";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import "../../pages/emailing/Emailing.css";
import { useMutation, useQuery } from "react-query";
import {
  createEmailTemplate,
  deleteEmailTemplate,
  getEmailTemplateById,
  updateEmailTemplate,
} from "backend/api/emailing";
import EmailPreview from "./EmailPreview/EmailPreview";
import { EmailTemplateTypeEnum } from "helpers/constants";
import { Language } from "./EmailPreview/EmailPreview.type";
import { StyledIconButton } from "./AssignEmail/AssignEmail.style";

interface CreateTemplateProps {
  setAlertMessage: Function;
  refetchTemplates: Function;
  allListTemplates: any[];
  t: any;
}

const CreateTemplate: React.FC<CreateTemplateProps> = ({
  setAlertMessage,
  refetchTemplates,
  allListTemplates,
  t,
}) => {
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const [isLocked, setIsLocked] = useState(false);
  const [listChips, setListChips] = useState<any>([]);
  const [triggerChipSelected, setTriggerChipSelected] = useState<any>("get");
  const [chipsSelected, setChipsSelected] = useState("");
  const [chipsDeleteSelected, setChipsDeleteSelected] = useState("");
  const [withPromo, setWithPromo] = useState(false);
  const [discountCode, setDiscountCode] = useState("");
  const [language, setLanguage] = useState("fr");
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [typeTemplate, setTypeTemplate] = useState<EmailTemplateTypeEnum | "">(
    "",
  );
  const [templateFields, setTemplateFields] = React.useState({
    name: "",
    hours: "",
    discount_number: "",
    content: "",
    contentEn: "",
  });

  const [file, setFile] = useState<any>(null);
  const [base64, setBase64] = useState<string>("");
  const [mimeType, setMimeType] = useState<string>("");
  const { content, contentEn, discount_number, hours, name } = templateFields;

  useQuery(["email-template-by-id", chipsSelected], {
    queryFn: () => getEmailTemplateById(chipsSelected),
    refetchOnWindowFocus: false,
    enabled: !!chipsSelected,
    onSuccess(data) {
      setChipsSelected(data?.id);
      setTemplateFields({
        name: data?.name,
        hours: data?.hours,
        discount_number: data?.discountNumber || "",
        content: data?.content,
        contentEn: data?.contentEn,
      });
      setWithPromo(!!data?.discountNumber);
      setDiscountCode(data?.code ?? "");
      setTypeTemplate(data?.type);
      setFile({
        name: data?.fileName,
      });
    },
  });

  const { mutate: mutateCreateEmail } = useMutation({
    mutationFn: (data: any) => createEmailTemplate(data),
    onSuccess: (result: any) => {
      setTriggerChipSelected("post");
      setAlertMessage({ type: "success", message: result.message });
      refetchTemplates();
      handleResetState();
    },
    onError: (error: any) => {
      console.log(error);
      setAlertMessage({ type: "error", message: error.message });
    },
  });

  const { mutate: mutateUpdateEmail } = useMutation({
    mutationFn: (data: any) => updateEmailTemplate(data),
    onSuccess: (result: any) => {
      setTriggerChipSelected("id");
      setAlertMessage({ type: "success", message: result.message });
      refetchTemplates();
    },
    onError: (error: any) => {
      console.log(error);
      setAlertMessage({ type: "error", message: error.message });
    },
  });

  const { mutate: mutateDeleteEmail, isLoading: isLoadingDeleteEmail } =
    useMutation({
      mutationFn: (id: string) => deleteEmailTemplate(id),
      onSuccess: (result: any) => {
        setTriggerChipSelected("get");
        setIsDeleteOpen(false);
        setAlertMessage({ type: "success", message: result.message });
        refetchTemplates();
        handleResetState();
      },
      onError: (error: any) => {
        console.log(error);
        setAlertMessage({ type: "error", message: error.message });
      },
    });

  useEffect(() => {
    setTemplateFields({
      ...templateFields,
      hours: "",
    });
  }, []);

  useEffect(() => {
    setListChips(allListTemplates);
    let newChipSelected;
    if (triggerChipSelected === "get") {
      newChipSelected = allListTemplates?.[0] || {};
    }
    if (triggerChipSelected === "post") {
      newChipSelected = allListTemplates?.[allListTemplates.length - 1] || {};
    }
    if (triggerChipSelected === "id") {
      newChipSelected =
        allListTemplates.find(
          (template: any) => template.id === chipsSelected,
        ) || {};
    }

    setChipsSelected(newChipSelected.id);
  }, [allListTemplates]);

  const registerTemplate = () => {
    const { name, hours, content, contentEn } = templateFields;

    const payload = {
      name,
      type: typeTemplate,
      discount_number: Number(discount_number) || null,
      hours: Number(hours),
      content,
      contentEn: contentEn || "",
      file: {
        name: file?.name || "",
        base64File: base64,
        mimeType,
      },
    };

    mutateCreateEmail(payload);
  };

  const updateTemplate = () => {
    const { name, discount_number, hours, content, contentEn } = templateFields;

    let payload: any = {
      id: chipsSelected,
      name,
      type: typeTemplate,
      discount_number: withPromo ? Number(discount_number) : null,
      hours: Number(hours),
      content,
      contentEn: contentEn || "",
      file: {
        name: file?.name || "",
        base64File: base64,
        mimeType,
      },
    };

    mutateUpdateEmail(payload);
  };

  const handleDeleteEmail = () => {
    if (chipsDeleteSelected === "") {
      const newListChip = listChips.filter((v: any) => v.id !== "");
      setListChips(newListChip);
      setIsDeleteOpen(false);

      const newChipSelected = newListChip?.[0];

      setChipsSelected(newChipSelected?.id ?? "");

      setTemplateFields({
        name: newChipSelected?.name ?? "",
        hours: newChipSelected?.hours ?? "",
        discount_number: newChipSelected?.discount_number ?? "",
        content: newChipSelected?.content ?? "",
        contentEn: newChipSelected?.contentEn ?? "",
      });
      setTypeTemplate(newChipSelected?.type ?? "");
      setFile({
        name: newChipSelected?.file ?? "",
      });
    } else mutateDeleteEmail(chipsDeleteSelected);
  };

  const handleResetState = () => {
    setTemplateFields({
      name: "",
      hours: "",
      discount_number: "",
      content: "",
      contentEn: "",
    });
    setTypeTemplate("");
    setFile(null);
    setBase64("");
    setMimeType("");
  };

  const noteHoursField = useMemo(() => {
    switch (typeTemplate) {
      case EmailTemplateTypeEnum.REVIEW_REQUEST:
        return "emailing.descReview";
      case EmailTemplateTypeEnum.REMINDER:
        return "emailing.descReminder";
      case EmailTemplateTypeEnum.LOYALTY:
        return "emailing.descLoyalty";
      default:
        return "";
    }
  }, [typeTemplate]);

  return (
    <div className="card">
      <Modal
        open={isDeleteOpen}
        onClose={() => setIsDeleteOpen(false)}
        style={{
          maxWidth: "800px",
          margin: "0 auto",
        }}
      >
        <Box>
          <Box
            sx={{
              color: "#004346",
              fontWeight: "700",
              fontSize: "26px",
              padding: 0,
              textAlign: "center",
            }}
          >
            {t("modalDelete.title")}
          </Box>
          <Box
            sx={{
              color: "#004346",
              fontWeight: "700",
              fontSize: "20px",
              padding: 0,
              textAlign: "center",
              mt: "15px",
              mb: "25px",
            }}
          >
            {t("modalDelete.desc")}
          </Box>

          <Box
            sx={{
              padding: 0,
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              margin: "auto",
              gap: "15px",
              width: "70%",
            }}
          >
            <Button
              sx={{
                bgcolor: "#FFE0764A",
                borderRadius: "30px",
                color: "#7E4501",
                fontWeight: "600",
                "&:hover": {
                  backgroundColor: "#f9ca3048",
                  cursor: "pointer",
                },
              }}
              variant="contained"
              disabled={isLoadingDeleteEmail}
              onClick={handleDeleteEmail}
            >
              {t("modalDelete.buttonSecondary")}
            </Button>

            <Button
              sx={{
                bgcolor: "#2CA58D",
                borderRadius: "30px",
                fontWeight: "600",
                "&:hover": {
                  backgroundColor: "#248370",
                  cursor: "pointer",
                },
              }}
              variant="contained"
              onClick={() => setIsDeleteOpen(false)}
            >
              {t("modalDelete.buttonPrimary")}
            </Button>
          </Box>
        </Box>
      </Modal>
      <StyledIconButton
        aria-label="close"
        color="primary"
        onClick={() => {
          setIsLocked(!isLocked);
        }}
      >
        {isLocked ? (
          <LockOpenIcon
            fontSize={"small"}
            color={"secondary"}
            style={{ cursor: "pointer" }}
          />
        ) : (
          <LockIcon
            fontSize={"small"}
            color={"primary"}
            style={{ cursor: "pointer" }}
          />
        )}
      </StyledIconButton>

      <h2 className="title">{t("emailing.title")}</h2>
      <div className="list-chip">
        {listChips.map((chip: any, index: React.Key | null | undefined) => (
          <span
            key={index}
            className={chip.id === chipsSelected ? "chip-active" : "chip"}
            style={{
              cursor: isLocked ? "pointer" : "default",
              position: "relative",
              padding: chip.id === "" ? "10px 22px" : "8px 30px 8px 22px",
              backgroundColor:
                chip.id === chipsSelected && isLocked
                  ? "#2ca58d"
                  : isLocked
                    ? "#f3fcfa"
                    : "#f3fcfa",
              color:
                chip.id === chipsSelected && isLocked ? "white" : "#2CA58D",
            }}
            onClick={() => {
              if (!isLocked) return;

              setChipsSelected(chip.id);
            }}
          >
            {chip.name}

            {chip.id === chipsSelected && isLocked ? (
              <IconButton
                color="error"
                sx={{
                  p: "0px 0px 0px 5px",
                  cursor: "pointer",
                  position: "absolute",
                  right: 6,
                }}
                onClick={() => {
                  if (!isLocked) return;

                  setChipsDeleteSelected(chip.id);
                  setIsDeleteOpen(true);
                }}
              >
                <Cancel
                  sx={{
                    fontSize: 20,
                  }}
                />
              </IconButton>
            ) : (
              <></>
            )}
          </span>
        ))}

        {isLocked && (
          <IconButton
            color="primary"
            onClick={() => {
              if (!isLocked) return;
              if (listChips.find((chip: { id: string }) => chip.id === ""))
                return;

              setListChips([
                ...listChips,
                {
                  id: "",
                  name: "",
                  hours: "",
                  content: "",
                  contentEn: "",
                  type: "",
                  file: "",
                },
              ]);
              handleResetState();

              setChipsSelected("");
            }}
          >
            <AddCircleRoundedIcon />
          </IconButton>
        )}
      </div>
      <div className="row-card">
        <div className="card-section">
          <h3 className="title">{t("emailing.titleForm")}</h3>
          <TextField
            value={name}
            onChange={(v) => {
              setTemplateFields({
                ...templateFields,
                name: v.target.value,
              });
            }}
            label={t("emailing.labelName")}
            variant="outlined"
            disabled={!isLocked}
          />

          <FormControl fullWidth>
            <InputLabel id="emailing-type">
              {t("emailing.labelType")}
            </InputLabel>
            <Select
              labelId="emailing-type"
              id="emailing-type-select"
              value={typeTemplate}
              label={t("emailing.labelType")}
              onChange={(event: SelectChangeEvent<string>) =>
                setTypeTemplate(event.target.value as EmailTemplateTypeEnum)
              }
              disabled={!isLocked}
            >
              <MenuItem value={EmailTemplateTypeEnum.CONFIRMATION}>
                {t("emailing.templateConfirmation")}
              </MenuItem>
              <MenuItem value={EmailTemplateTypeEnum.CANCELLATION}>
                {t("emailing.templateCancellation")}
              </MenuItem>
              {/* WILL BE ADDED LATER */}
              {/* <MenuItem value={EmailTemplateTypeEnum.REMINDER}>
                {t("emailing.templateReminder")}
              </MenuItem>
              <MenuItem value={EmailTemplateTypeEnum.REVIEW_REQUEST}>
                {t("emailing.templateReview")}
              </MenuItem>
              <MenuItem value={EmailTemplateTypeEnum.LOYALTY}>
                {t("emailing.templateLoyalty")}
              </MenuItem> */}
            </Select>
          </FormControl>
          {typeTemplate !== EmailTemplateTypeEnum.CANCELLATION &&
            typeTemplate !== EmailTemplateTypeEnum.CONFIRMATION && (
              <>
                <TextField
                  type="text"
                  label={`${t("emailing.labelHours")}*`}
                  value={hours}
                  inputProps={{
                    min: 0,
                  }}
                  variant="outlined"
                  disabled={!isLocked}
                  onChange={(e) => {
                    const newValue = e.target.value;

                    if (/^\d*$/.test(newValue)) {
                      setTemplateFields((prevFields) => ({
                        ...prevFields,
                        hours: Number(newValue) < 0 ? "" : newValue,
                      }));
                    }
                  }}
                />
                <p className="desc">*{t(noteHoursField)}</p>
              </>
            )}
          {typeTemplate === EmailTemplateTypeEnum.LOYALTY && (
            <>
              <div className="switch">
                <Switch
                  disabled={!isLocked}
                  value={withPromo}
                  checked={withPromo}
                  onChange={(e) => {
                    if (e.target.checked === false) {
                      setTemplateFields((prev) => ({
                        ...prev,
                        amount: "",
                      }));
                    }
                    setWithPromo(e.target.checked);
                  }}
                />
                <FormLabel
                  sx={{
                    color: "#004346",
                  }}
                >
                  {t("emailing.withPromo")}
                </FormLabel>
              </div>
              {withPromo && (
                <TextField
                  type="text"
                  label={`${t("emailing.labelPromo")}*`}
                  value={discount_number}
                  inputProps={{
                    min: 0,
                  }}
                  variant="outlined"
                  disabled={!isLocked}
                  onChange={(e) => {
                    const newValue = e.target.value;

                    if (/^\d*$/.test(newValue)) {
                      setTemplateFields((prevFields) => ({
                        ...prevFields,
                        discount_number: Number(newValue) < 0 ? "" : newValue,
                      }));
                    }
                  }}
                />
              )}
            </>
          )}

          <h3 className="title">{t("emailing.titleContent")}</h3>

          <span style={{ color: "#2CA58D" }}>Fr :</span>
          <textarea
            disabled={!isLocked}
            className="textarea"
            value={content}
            onChange={(e) => {
              setTemplateFields({
                ...templateFields,
                content: e.target.value,
              });
            }}
            placeholder={t("emailing.placeholderTextarea")}
            rows={2}
          />

          <span style={{ color: "#2CA58D" }}>En :</span>
          <textarea
            disabled={!isLocked}
            className="textarea"
            value={contentEn}
            onChange={(e) => {
              setTemplateFields({
                ...templateFields,
                contentEn: e.target.value,
              });
            }}
            placeholder={t("emailing.placeholderTextarea")}
            rows={2}
          />

          <div className="attachment">
            <span>{t("emailing.inputFile")}</span>
            <div
              className="icon"
              style={{
                cursor: !isLocked ? "default" : "pointer",
              }}
              onClick={() => (!isLocked ? null : fileInputRef.current?.click())}
            >
              <CloudUploadIcon style={{ color: "#28a088" }} />
            </div>
            <div
              style={{
                position: "relative",
              }}
            >
              {file?.name && isLocked && (
                <IconButton
                  color="error"
                  sx={{
                    p: "0px 0px 0px 5px",
                    cursor: "pointer",
                    position: "absolute",
                    left: -10,
                  }}
                  onClick={() => {
                    if (!isLocked) return;

                    setFile(null);
                    fileInputRef.current?.value &&
                      (fileInputRef.current.value = "");
                    setBase64("");
                    setMimeType("");
                  }}
                >
                  <Cancel
                    sx={{
                      fontSize: 20,
                    }}
                  />
                </IconButton>
              )}

              <p
                style={{
                  width: "320px",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {file?.name}
              </p>
            </div>

            <input
              type="file"
              accept="image/jpeg, image/png, application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              ref={fileInputRef}
              onChange={(e) => {
                if (e.target.files && e.target.files.length > 0) {
                  const file = e.target.files[0];
                  setFile(file);

                  const mimeType = file.type;
                  setMimeType(mimeType);

                  const reader = new FileReader();
                  reader.onloadend = () => {
                    const base64 = reader.result as string;
                    setBase64(base64);
                  };

                  reader.readAsDataURL(file);
                }
              }}
              style={{ display: "none" }}
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              bottom: "20px",
              padding: "0px 20px",
            }}
          >
            <div></div>
            <Button
              variant="contained"
              color="info"
              disabled={!isLocked || !name || !content}
              onClick={chipsSelected === "" ? registerTemplate : updateTemplate}
              sx={{
                borderRadius: "20px",
                padding: "5px 25px",
              }}
            >
              {t("emailing.buttonPrimary")}
            </Button>
          </div>
        </div>
        <div className="card-section">
          <EmailPreview
            t={t}
            type={typeTemplate}
            amount={discount_number}
            content={language === "fr" ? content : contentEn}
            filename={file?.name}
            language={language as Language}
            setLanguage={setLanguage}
            discountCode={discountCode}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateTemplate;
