export const BOOKINGS_COLUMN_STYLES = {
  0: { cellWidth: 20 },
  1: { cellWidth: 20 },
  2: { cellWidth: 20 },
  3: { cellWidth: 25 },
  4: { cellWidth: 40 },
  5: { cellWidth: 16 },
  6: { cellWidth: 20 },
  7: { cellWidth: 17 },
  8: { cellWidth: 20 },
  9: { cellWidth: 17 },
  10: { cellWidth: 20 },
  11: { cellWidth: 17 },
  12: { cellWidth: 17 },
};
