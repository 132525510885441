import React from "react";

const PayzenLogo = () => {
  return (
    <svg
      version="1.0"
      width="6rem"
      height="2rem"
      viewBox="0 0 1931.000000 621.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,621.000000) scale(0.100000,-0.100000)"
        fill="#6772e5"
        stroke="none"
      >
        <path
          d="M8490 3105 l0 -3105 5335 0 5335 0 0 3105 0 3105 -1630 0 -1630 0 0
    -285 0 -285 1345 0 1345 0 0 -2545 0 -2545 -4762 2 -4763 3 -3 2543 -2 2542
    2925 0 2925 0 0 285 0 285 -3210 0 -3210 0 0 -3105z"
        />
        <path d="M19200 3105 l0 -3105 55 0 55 0 0 3105 0 3105 -55 0 -55 0 0 -3105z" />
        <path
          d="M488 4713 l-488 -3 0 -1625 0 -1625 305 0 305 0 0 479 0 480 563 4
    c531 4 567 5 655 25 377 87 631 286 788 616 135 285 144 652 24 953 -136 341
    -413 576 -776 661 -138 33 -284 42 -599 40 -159 -2 -509 -4 -777 -5z m1195
    -598 c303 -64 489 -369 422 -690 -35 -166 -143 -309 -286 -378 -130 -63 -168
    -67 -719 -67 l-490 0 0 575 0 575 503 0 c398 0 516 -3 570 -15z"
        />
        <path
          d="M10314 4428 l-60 -283 799 -3 c478 -1 797 -6 795 -11 -2 -5 -260
    -288 -574 -628 -314 -340 -714 -773 -889 -963 -175 -190 -345 -374 -379 -410
    -34 -36 -112 -120 -175 -188 l-114 -122 -34 -163 c-18 -89 -36 -170 -39 -180
    -5 -16 58 -17 1267 -15 l1272 3 59 275 c32 151 58 281 58 288 0 9 -174 12
    -836 12 -525 0 -834 4 -832 10 2 7 427 474 679 744 84 91 987 1071 1254 1361
    60 66 137 148 170 183 33 35 65 73 72 85 11 19 63 250 63 277 0 7 -430 10
    -1248 10 l-1248 0 -60 -282z"
        />
        <path
          d="M13940 3804 c-716 -111 -1237 -704 -1267 -1441 -11 -282 57 -496 210
    -660 75 -80 141 -130 233 -176 361 -180 892 -161 1332 48 98 47 234 129 297
    180 25 20 28 14 -128 208 -67 83 -125 155 -128 160 -4 5 -22 -2 -40 -16 -92
    -69 -295 -147 -453 -172 -318 -52 -592 33 -700 218 -32 54 -58 155 -54 209 l3
    43 895 5 894 5 13 45 c8 25 25 95 38 155 142 654 -76 1068 -625 1182 -108 22
    -399 26 -520 7z m367 -512 c86 -27 143 -63 197 -123 56 -62 89 -156 84 -240
    l-3 -54 -612 -3 c-510 -2 -613 0 -613 11 0 8 18 42 40 76 118 183 298 302 525
    347 99 19 300 12 382 -14z"
        />
        <path
          d="M3900 3799 c-159 -16 -361 -78 -480 -146 -274 -159 -441 -401 -521
    -758 -22 -98 -19 -487 5 -600 36 -171 107 -342 191 -460 49 -69 179 -197 245
    -242 207 -140 535 -221 785 -193 266 29 521 162 633 329 l40 61 6 -48 c3 -26
    8 -87 11 -137 3 -49 8 -102 11 -117 l6 -28 264 0 264 0 0 1145 0 1145 -269 0
    -269 0 -6 -22 c-3 -13 -6 -61 -7 -108 0 -47 -4 -105 -7 -129 l-7 -45 -42 56
    c-120 159 -328 268 -573 298 -95 11 -157 11 -280 -1z m383 -515 c121 -27 211
    -77 303 -168 64 -64 90 -99 122 -166 63 -128 77 -192 76 -350 0 -125 -3 -142
    -33 -229 -55 -162 -131 -266 -252 -347 -281 -186 -692 -130 -902 124 -56 67
    -93 139 -123 237 -23 76 -27 110 -28 215 -2 226 52 369 193 511 86 86 182 140
    303 170 93 22 246 24 341 3z"
        />
        <path
          d="M16925 3783 c-194 -25 -412 -118 -600 -258 -55 -40 -101 -68 -103
    -62 -1 7 4 73 12 147 8 74 12 138 9 143 -4 7 -503 10 -503 3 0 -3 -43 -206
    -96 -453 -52 -246 -110 -520 -129 -608 -18 -88 -48 -225 -65 -305 -17 -80 -44
    -208 -60 -285 -16 -77 -39 -183 -50 -235 -12 -52 -25 -115 -30 -140 -5 -25
    -18 -88 -29 -140 -12 -52 -21 -103 -21 -112 0 -17 20 -18 278 -18 l277 0 123
    583 c68 320 133 614 144 652 11 39 39 107 63 153 142 268 377 422 645 422 238
    0 376 -103 426 -317 20 -83 14 -197 -16 -333 -12 -52 -34 -158 -50 -235 -16
    -77 -39 -183 -50 -235 -42 -196 -51 -239 -80 -375 -16 -77 -36 -171 -45 -210
    -25 -117 -55 -105 264 -105 l279 0 16 68 c14 61 32 143 131 617 19 88 52 246
    75 350 58 272 70 354 70 495 0 192 -33 325 -115 464 -101 173 -268 282 -490
    321 -81 14 -207 18 -280 8z"
        />
        <path
          d="M5650 3745 c0 -3 11 -31 24 -63 13 -31 63 -154 111 -272 48 -118 123
    -300 165 -405 43 -104 112 -274 154 -377 42 -104 110 -272 152 -375 42 -104
    112 -276 156 -383 43 -107 95 -234 114 -281 l35 -87 -26 -63 c-15 -35 -110
    -256 -211 -490 -101 -235 -184 -429 -184 -433 0 -3 139 -6 309 -6 l308 0 28
    63 c15 34 166 384 335 777 169 393 374 868 455 1055 224 518 575 1336 575
    1341 0 2 -141 4 -312 4 l-313 -1 -100 -241 c-55 -133 -114 -276 -131 -317 -17
    -42 -62 -148 -99 -236 -57 -136 -155 -388 -287 -743 -17 -46 -34 -79 -38 -75
    -4 4 -50 136 -103 293 -52 157 -177 518 -278 803 l-182 517 -328 0 c-181 0
    -329 -2 -329 -5z"
        />
      </g>
    </svg>
  );
};

export default PayzenLogo;
