import React, { useMemo } from "react";

interface AlertHeaderProps {
  t: any;
}

export default function AlertHeader({ t }: AlertHeaderProps) {
  const columns = useMemo(() => {
    return [
      {
        Header: t("activity"),
        accessor: "activity",
      },
      {
        Header: t("targetGauge"),
        accessor: "targetGauge",
      },
      {
        Header: t("ratesConcerned"),
        accessor: "ratesConcerned",
      },
      {
        Header: t("confirmationOfReaching"),
        accessor: "confirmationOfReaching",
      },
      {
        Header: t("alertOfNotReaching"),
        accessor: "alertOfNotReaching",
      },
    ];
  }, [t]);

  return (
    <thead>
      <tr>
        {columns.map((column) => (
          <th key={column.accessor}>{column.Header}</th>
        ))}
      </tr>
    </thead>
  );
}
