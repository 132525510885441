import axios from "axios";
import { getAuthorizedConfig } from "../../helpers/helpers";
import {
  PaymentConnectVerificationOutput,
  PayzenAccountData,
} from "components/paymentAccountConnect/PaymentTypes";

export async function stripeConnect(): Promise<string> {
  let config = getAuthorizedConfig("POST");
  config["url"] = `${process.env.REACT_APP_API_URL}/clients/stripe/connect`;
  const response = await axios.request(config);
  return response.data;
}

export async function paymentAccountConnectVerification(
  clientId: string | number,
): Promise<PaymentConnectVerificationOutput> {
  let config = getAuthorizedConfig("GET");
  config["url"] =
    `${process.env.REACT_APP_API_URL}/clients/payment-account/verification/${clientId}`;
  const response = await axios.request(config);
  return response.data;
}

export const savePayzenAccount = async (data: PayzenAccountData) => {
  let config = getAuthorizedConfig("POST");
  config["url"] = `${process.env.REACT_APP_API_URL}/clients/payzen/connect`;
  config["data"] = data;
  const response = await axios.request(config);
  return response.data;
};
