const discounts: any = {
  en: {
    channels: "my channels",
    website: "website",
    widgetsSectionTitle: "my bookings widgets",
    widgetNameLabel: "Widget name",
    activitiesSelectorLabel:
      "Activities displayed by the booking widget if enabled",
    widgetCreationBtn: "create/edit booking module",
    iframeCodeLabel: "widget code to copy into your websites",
    codeCopyBtn: "copy code",
    copyIframeCodeSuccessMsg:
      "Iframe code is copied successfully to your clipboard",
    iframeStyleSectionTitle: "booking module configuration",
    iframeStyleSectionSubtitle: "appearance of the booking module",
    widgetFontFamily: "Text font",
    widgetColors: {
      widgetBackgroundColor: "Background color",
      textColorOnBackground: "Text color on background",
      activityCardBackgroundColor: "Activity card background color",
      activityCardTextColor: "Activity card text color",
      actionBtnBackgroundColor: "Action button background color",
      actionBtnTextColor: "Action button text color",
    },
    infosSectionTitle: "informations requested when booking",
    infosSectionText:
      "The information that must be requested from the visitor is: full name, email, telephone number.",
    addInfoBtnLabel: "Add information to ask the visitor",
    extraInfoInputLabel: "Additional information required for booking #",
    answerRequired: "Answer required",
    answersList: "Answers List",
    answers: "Answers",
    ansersListTooltipText:
      "Enter the answers you wish to suggest in your drop-down list, separating them with a comma.",
    saveDesignBtn: "save configuration",
    updateWidgetSuccessfully: "is Updated successfully!",
    createWidgetSuccessfully: "is created successfully!",
    deleteWidgetSuccessfully: "is deleted successfully!",
    deleteWidgetWarningTitle: " Warning : delete widget",
    deleteWidgetWarningContent: "Do you want to delete the selected widget?",
    isPinnedDiscount: "Pin & promote a promo code on this booking page",
    noDiscountsAvailable:
      "No promotions are available for the activities in this widget.",
    confirm: "Confirm",
    close: "Close",
    groupQuotes: "Activation of the group quote form",
    groupQuoteError: "Unavailable in your current offer.",
    groupQuoteErrorLink: "Click here to learn more.",

    errors: {
      widgetName: "Please enter a widget name that exceeds 2 characters",
      activitiesIds: "Please choose at least one activity",
      extraInfo: "Please enter a questions or info that exceeds 5 characters",
      copyIframeCodeErrorMsg: "Error while copying iframe code",
      updateWidget: "Error creating or updating Widget",
      deleteWidget: "Error deleting selected Widget",
      widgetNameAlreadyUsed: "Widget Name is already used",
      maxExtraInfos: "You can add maximum 3 extra informations",
    },
  },
  fr: {
    channels: "mes canaux",
    website: "site internet",
    widgetsSectionTitle: "mes widgets de réservation",
    widgetNameLabel: "Nom de widget",
    activitiesSelectorLabel:
      "Activités affichées par le widget de réservations si activées",
    widgetCreationBtn: "créer/modifier le module de réservation",
    iframeCodeLabel: "code du widget a coller dans votre site internet",
    codeCopyBtn: "copier le code",
    copyIframeCodeSuccessMsg:
      "Le code Iframe a été copié avec succès dans votre presse-papiers",
    iframeStyleSectionTitle: "configuration du module de réservation",
    iframeStyleSectionSubtitle: "apparence du module de reservation",
    widgetFontFamily: "Police du texte",
    widgetColors: {
      widgetBackgroundColor: "Couleur du fond",
      textColorOnBackground: "Couleur du texte sur le fond",
      activityCardBackgroundColor:
        "Couleur de fond des encarts présentant chaque activité",
      activityCardTextColor:
        "Couleur du texte des encarts présentant chaque activité",
      actionBtnBackgroundColor: "Couleur de fond des boutons d’action",
      actionBtnTextColor: "Couleur du texte des boutons d’action",
    },
    infosSectionTitle: "informations demandées a la reservation",
    infosSectionText:
      "Les informations obligatoirement demandées au visiteur sont : nom complet, email, numéro de téléphone.",
    addInfoBtnLabel: "Ajouter une information à demander au visiteur",
    extraInfoInputLabel:
      "Information supplémentaire nécessaire pour la réservation #",
    answerRequired: "Réponse obligatoire",
    answersList: "Liste de réponses",
    answers: "Réponses",
    ansersListTooltipText:
      "Saisissez les réponses que vous souhaitez proposer dans votre liste déroulante en les séparant par une virgule.",
    saveDesignBtn: "sauvegarder la configuration",
    updateWidgetSuccessfully: "est mis à jour avec succès !",
    createWidgetSuccessfully: "est crée avec succès !",
    deleteWidgetSuccessfully: "est supprimée avec succès !",
    deleteWidgetWarningTitle: " Avertissement : suppression widget",
    deleteWidgetWarningContent: "Voulez-vous supprimer le widget sélectionné ?",
    isPinnedDiscount:
      "Epingler & promouvoir un code promotion sur cette page de réservation",
    noDiscountsAvailable:
      "Aucune promotion n'est disponible pour les activités de ce widget",
    confirm: "Confirmer",
    close: "Fermer",
    groupQuotes: "Activation du formulaire de devis de groupe",
    groupQuoteError: "Indisponible dans votre offre actuelle.",
    groupQuoteErrorLink: "Cliquez-ici pour en savoir plus.",
    errors: {
      widgetName: "Veuillez saisir un nom de widget qui dépasse 2 caractères",
      activitiesIds: "Veuillez choisir au moins une activité",
      extraInfo:
        "Veuillez saisir une question ou information qui dépasse 5 caractères",
      copyIframeCodeErrorMsg: "Erreur durant la copie de code d'iframe",
      updateWidget: "Erreur lors de la creation ou mise à jour de Widget ",
      deleteWidget: "Erreur lors de la suppression de Widget selectionnée",
      widgetNameAlreadyUsed: "Le nom de Widget est deja utilisé",
      maxExtraInfos:
        "Vous pouvez ajouter au maximum 3 informations supplémentaires",
    },
  },
};

export default discounts;
