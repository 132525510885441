import { Button } from "@mui/material";
import {
  FourthTitleText,
  GreenBoxWithTitle,
  Row,
  SecondaryTitleText,
  ThirdTitleText,
} from "../../../helpers/generalStyles";
import { Activity } from "../../../backend/api/activities";
import {
  BookingButtonWrapper,
  BookingFormBody,
  BookingFormSection,
} from "../bookingsStyles";
import { UseFormReturn } from "react-hook-form";
import { BookingFormValidator } from "./bookingFormValidator";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import { ActivityPricesTypeEnum, ActivityTypeEnum } from "helpers/constants";
import { getBookingPrice } from "./bookingUtils";

export default function BookingFormGroup3(props: {
  form: UseFormReturn<BookingFormValidator>;
  activity: Activity;
  setPage?: any;
  t: any;
}) {
  const { form, activity, setPage, t } = props;
  const isInCarroussel = setPage !== undefined ? true : false;
  const values = form.getValues();
  let price = 0;
  if (activity.informations.bookingSettings.type === ActivityTypeEnum.GROUP) {
    const groupPrice: any = values.prices.find(
      (price) => price.name === ActivityPricesTypeEnum.GROUP,
    );
    price = getBookingPrice(groupPrice, groupPrice?.number) ?? price;
  } else {
    price = values.prices.reduce(
      (sum: any, curr: any) =>
        (sum +=
          Number(curr.number) * (getBookingPrice(curr, curr.number) ?? 0)),
      0,
    );
  }

  const onPreviousPage = () => {
    setPage(2);
  };

  return (
    <BookingFormBody style={{ justifyContent: "center" }}>
      <BookingFormSection
        style={{
          justifyContent: "space-around",
          borderBottom: "solid 1px lightgrey",
        }}
      >
        <GreenBoxWithTitle> {activity.informations.name}</GreenBoxWithTitle>
        <div style={{ display: "flex", alignItems: "center" }}>
          <EventAvailableIcon />
          <FourthTitleText>{`${values.date.format("DD-MMMM-YYYY")} | ${values.slots[0]?.from} - ${values.slots[0]?.to}`}</FourthTitleText>
        </div>
      </BookingFormSection>
      <BookingFormSection style={{ flex: 3 }}>
        {values.prices.map((price: any, index) => (
          <FourthTitleText
            key={index}
            style={{ textAlign: "left" }}
          >{`${price.number} x  ${price.name === ActivityPricesTypeEnum.GROUP ? t("numberOfUnitInGroup") : price.name}`}</FourthTitleText>
        ))}
        <SecondaryTitleText style={{ justifySelf: "flex-end" }}>
          {t("totalPrice")}
        </SecondaryTitleText>{" "}
        <ThirdTitleText>{`${price.toFixed(2)} €`}</ThirdTitleText>
        <BookingButtonWrapper>
          <Button
            style={{
              display: isInCarroussel ? "initial" : "none",
            }}
            color="info"
            variant="text"
            onClick={onPreviousPage}
            type="button"
          >
            {t("previous")}
          </Button>
          <Button
            style={{
              display: isInCarroussel ? "initial" : "none",
            }}
            color="info"
            variant="contained"
            type="submit"
          >
            {t("confirm")}
          </Button>
        </BookingButtonWrapper>
      </BookingFormSection>
    </BookingFormBody>
  );
}
