const alert: any = {
  en: {
    alertPageTitle: "Gauge Alerts",
    activity: "Activity",
    targetGauge: "Target gauge",
    ratesConcerned: "Rates concerned",
    confirmationOfReaching: "Confirmation of reaching the gauge by email",
    alertOfNotReaching: "Alert of not reaching the gauge by email",
    person: "Person",
    group: "Group",
    concernedStaff: "To the staff members concerned",
    concernedClient: "To the concerned customers",
    cancelSlot: "Cancel the slot and notify the affected customers",
    sendDate: "Send date (in hours before the slot)",
    saveAlert: "Save Gauge Alerts",
    success: "Alert successfully created",
  },
  fr: {
    alertPageTitle: "Alertes de Jauge",
    activity: "Activité",
    targetGauge: "Jauge cible",
    ratesConcerned: "Tarifs concernés",
    confirmationOfReaching: "Confirmation d’atteinte de la jauge par mail",
    alertOfNotReaching: "Alerte de non atteinte de la jauge par mail",
    person: "Personne",
    group: "Groupe",
    concernedStaff: "Aux membres du staff concernés",
    concernedClient: "Aux clients concernées",
    cancelSlot: "Annuler le créneau et notifier les clients concernées",
    sendDate: "Date d’envoi (en heures avant le créneau)",
    saveAlert: "Sauvegarder Les Alertes De Jauge",
    success: "Alerte créée avec succès",
  },
};

export default alert;
