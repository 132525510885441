import { FormControl, IconButton, styled } from "@mui/material";

export const WrapperButton = styled("div")({
  width: "100%",
  display: "flex",
  justifyContent: "center",
});

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  width: "150px",
  color: theme.palette.info.main,
  justifyContent: "center",
  "&:hover": {
    backgroundColor: "white !important",
  },
}));

export const StyledFormControl = styled(FormControl)`
  width: 100%;
  position: relative;
`;
