export function getUniqueMap<T, K>(
  items: T[],
  keyExtractor: (item: T) => K,
): Map<K, T> {
  const uniqueMap = new Map<K, T>();
  items.forEach((item) => {
    const key = keyExtractor(item);
    if (!uniqueMap.has(key)) {
      uniqueMap.set(key, item);
    }
  });
  return uniqueMap;
}

export function mapValuesToArray<K, V>(map: Map<K, V>): V[] {
  return Array.from(map.values());
}
